.funnels-container {
    width: 100%;
    height: 100vh;
    user-select: none;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: var(--text-color);
    .funnel {
        /* padding: 50px !important; */
        /* border: 1px solid red; */
        position: absolute;
        &-control {
            button {
                // background: #545b67;
                border-radius: 15px;
                margin-bottom: 5px;
            }
        }
        &-limit {
            position: absolute;
            bottom: 25px;
            left: 50%;
            transform: translateX(-50%);
            width: 800px;
            min-height: 115px;
            z-index: 99;
        }
        @media screen and (max-width: 990px) {
            .funnel-limit {
                width: 60%
            }
        }
    }

    .node-group-item {
        border: none;
        border-radius: 6px;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        width: 164px;
        height: 30px;
        color: #fff;
        background: #353f4c;
    }
    .node-group-item.true {
        background: rgb(45 72 69);
    }
    .node-group-item.false {
        background: rgb(66 54 67);
    }
    /* Selected Block */
    /* .react-flow__node.selectable.selected {
            border: 2px solid #9747ff;
            border-radius: 6px;
            box-sizing: border-box;
        } */

    /* Edges */
    // .react-flow .react-flow__edges {
    //     z-index: 999999999 !important;
    // }
    g.react-flow__edge:hover path.react-flow__edge-path {
        stroke-width: 5px !important;
    }
    path.react-flow__edge-path:hover {
        cursor: pointer;
        stroke: #007aff !important;
    }
    .selected path.react-flow__edge-path {
        border: 5px solid blue !important;
        stroke: #9747ff !important;
    }

    .react-flow__node-default .react-flow__handle,
    .react-flow__node-group .react-flow__handle,
    .react-flow__node-input .react-flow__handle,
    .react-flow__node-output .react-flow__handle {
        border: 1px solid var(--text-color);
        border-radius: 0px;
        background: #fff;
        width: 8px;
        height: 8px;
    }
    .custom-handle {
        top: 22px;
        border-radius: 0px;
        border: 1px solid var(--text-color);
        background: #fff;
        width: 8px;
        height: 8px;
    }
    .StartBlock,.ButtonBlock {
        .custom-handle {
            top: 50%;
        }
    }
}

.p-image-mask {
    z-index: 9999;
}