.p-button.p-component.btn-upload_file {
    background-color: var(--surface-ground);
    width: 100%;
    height: 100px;
    border: 1px dashed #007AFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px 0;
}

.p-button.p-component.btn-upload_file.drag {
    border: 1px dashed #9747ff;
}

.p-button.p-component.btn-upload_file i {
    color: var(--text-color);
}

.p-button.p-component.btn-upload_file p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--text-color);
    max-width: 245px;
    text-align: start;
    margin: 0 0 0 16px;
}

.p-button.p-component.btn-upload_file span {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
}

.uploaded-file_container {
    display: flex;
    justify-content: space-between;
    background-color: var(--surface-ground);
    border-radius: 6px;
    padding: 10px 20px;
    height: 52px;
    margin: 32px 0;
}

.uploaded-file {

    display: flex;
    align-items: center;
}

.img-uploaded {
    width: 32px;
    height: 32px;
    border-radius: 6px;
}

.name-uploaded_file {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin-left: 16px;
}

.p-button.p-component.btn-delete_file {
    background-color: transparent;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
}