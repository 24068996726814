.error-confirm {
    border: 1px solid #FF3B30;
    border-radius: 6px;
    padding: 20px;
    margin-top: 32px;
    margin-bottom: 30px;
}

.title-error {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
}

.error-confirm_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.error-confirm_header i {
    color: #FF3B30;
}

.error-confirm_description {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #78909C;
}

.p-button.p-component.resend-email_btn {
    background-color: var(--text-color);
    color: var(--text-color-contrast);
    width: 100%;
    margin-top: 10px;
}

.success-resend {
    border: 1px solid var(--primary-color);
    background-color: var(--surface-ground);
    border-radius: 6px;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #78909C;
    margin-top: 32px;
    margin-bottom: 30px;
}