.card-charts {
    width: 49%;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--surface-card);
    height: 430px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-charts_header {
    display: flex;
    justify-content: space-between;
}

.btn-sort {
    width: 120px;
    height: 48px;
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: transparent;
    border: 1px solid #FFFFFF1A;
    padding: 10px 15px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    color: var(--text-color);
}

.p-button.btn-sort:hover {
    background-color: transparent;
    border: 1px solid #FFFFFF1A;
    color: var(--text-color);
}

.title-funnel {
    font-size: 12px;
    font-weight: 700;
    line-height: 17px;
    color: var(--primary-color);
    margin-top: 0;
}

.amount-views {
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    color: var(--text-color);
}

.starting-funnel_title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-color);
    margin-bottom: 0;
}

.starting-funnel_date {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #545B67;
    margin-bottom: 0;
}

.btn-favorites {
    background-color: transparent;
    width: 48px;
    height: 48px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-button.btn-favorites:hover {
    background-color: transparent;
    border: none;
}

.card-funnel_title {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    color: var(--text-color);
    margin-top: 0;
    margin-bottom: 10px;
}

.card-funnel_views {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    margin-bottom: 10px;
}

.card-funnel_views.problem {
    color: #FF3B30;
}

.card-funnel_views.yellow {
    color: #FFD60A;
}

.card-funnel_views.green {
    color: #28CD41;
}

@media screen and (max-width: 1210px) {
    .card-charts {
        width: 100%;
        margin-bottom: 10px;
    }
}