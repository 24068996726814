.portrait-avatar {
    width: 40px;
    height: 40px;
}

.portrait-name {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
}

.name-user svg {
    fill: var(--text-color) !important;
}

.portrait-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;

}

.geotags-container {
    overflow-x: auto;
    background-color: var(--surface-ground);
    border-radius: 6px;
    padding: 10px;
}

.geotags-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--text-color);
    padding-left: 8px;
}

.geotags-location {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: var(--text-color);
}

.geotags-ip_address {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--primary-color);
}

.geotags-date {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: var(--primary-color);
}

.utm_tags-title {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    color: var(--text-color);
}

.utm_tags-value {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--primary-color);
}

.history-actions_title {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--text-color);
}

.history-actions-container {
    border: 1px solid var(--surface-ground);
    border-radius: 6px;

}

.action {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--primary-color);
}

.date-action {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    color: var(--text-color);
}

.action-container {
    padding: 12px 20px;
    border-bottom: 1px solid var(--surface-ground);
}

.tag-container {
    padding: 12px 20px;
    border-radius: 20px;
    background-color: var(--surface-ground);
    margin-right: 8px;
    margin-bottom: 8px;
}

.add-tag_btn {
    background-color: var(--primary-color);
    border-radius: 20px;
    padding: 12px 20px;
    color: var(--text-color);
    border: none;
    margin-bottom: 8px;
}

.utm-tags_table .p-column-title {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    color: var(--text-color);
}

.utm-tags_table .p-datatable-thead tr th {
    padding: 10px !important;
}

.utm-tags_table {
    margin-top: 10px;
}

.utm-tags_table .p-datatable-tbody tr td:first-child {
    color: var(--primary-color);
}

.utm-tags_table .p-datatable-tbody tr td:last-child {
    color: var(--text-color);
}

.utm-tags_table .p-datatable-tbody tr {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--surface-border);
}

.utm-tags_table .p-datatable-tbody tr td {
    padding: 5px 10px !important;
}

.geotags_table .p-datatable-tbody tr {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--text-color);
}

.geotags_table .p-datatable-tbody tr td:first-child {
    font-weight: 700;
    line-height: 14px;
}

.geotags_table .p-datatable-tbody tr td:last-child {
    text-align: end;
}

.geotags_table .p-datatable-tbody tr td:nth-child(3) {
    color: var(--primary-color);
}

.geotags_table .p-datatable-thead {
    display: none;
}

.geotags_table .p-datatable-tbody tr td {
    padding: 5px 10px !important;
}

.p-button.p-button-outlined.btn-question {
    background-color: transparent;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-question svg {
    fill: var(--surface-border) !important;
}

.portrait-avatar svg {
    position: absolute;
    bottom: 0;
    right: -10px;
}

.portrait-avatar svg path:last-child {
    fill: var(--primary-color);
}

@media screen and (max-width: 1700px) {
    .portrait-name {
        font-size: 30px;
        line-height: 34px;
    }
}

@media screen and (max-width: 1500px) {
    .portrait-avatar {
        width: 90px;
        height: 90px;
    }

    .portrait-name {
        font-size: 26px;
        line-height: 30px;
    }
}

@media screen and (max-width: 1320px) {
    .portrait-avatar {
        width: 80px;
        height: 80px;
    }

    .portrait-name {
        font-size: 23px;
        line-height: 26px;
    }
}

.table-tags {
    margin-top: 40px;
}

.table-tags table,
.table-tags table tbody {
    display: flex;
}

.table-tags table thead {
    width: 15%;
}

.table-tags table tbody tr {
    width: 20%;

}

.table-tags table tbody {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: var(--text-color);
    width: 90%;
}

.table-tags table tr {
    display: flex;
    flex-direction: column;
}

.table-tags table th,
.table-tags table td {
    height: 30px;
}

.table-tags.geotags table th,
.table-tags.geotags table td {
    height: 35px;
}

.table-tags.devices table th,
.table-tags.devices table td {
    height: 45px;
}


.table-tags table thead tr th div span {
    font-size: 10px;
    font-weight: 700;
    line-height: 12px;
    text-align: left;
    color: #545B67;
    max-width: 15%;
}

.table-tags.p-datatable .p-datatable-thead>tr>th {
    padding: 0;
    padding-top: 7px;
    padding-bottom: 7px;
}

.table-tags .p-paginator {
    background-color: transparent;
    border: none;
}

.table-tags .p-paginator .p-button.p-button-icon-only {
    display: none;
}


.table-text.link {
    color: var(--primary-color);
}

.p-paginator .p-paginator-pages .p-paginator-page {
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: transparent;
    color: var(--text-color);
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.p-paginator-pages {
    display: flex;
    gap: 10px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background-color: var(--primary-color);
    color: var(--text-color);
    font-weight: 700;
}

@media screen and (max-width: 1855px) {
    .table-tags table tbody tr {
        width: 25%;
    }
}

@media screen and (max-width: 1645px) {
    .table-tags table tbody tr {
        width: 33%;
    }
}

@media screen and (max-width: 1350px) {
    .table-tags table tbody tr {
        width: 50%;
    }
}