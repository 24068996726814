.subtitle-card {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
}

.title-mailing {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: var(--text-color);
    margin-bottom: 32px;
}

.mailing_table.p-datatable .p-datatable-tbody>tr {
    height: 50px;
}

.subtitle-mailing {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);
    margin-bottom: 8px;
}

.title-mail .p-editor-container .p-editor-content.ql-snow {
    height: 40px !important;
    overflow: hidden;
}

.text-mail .p-editor-container .p-editor-content.ql-snow {
    height: 160px !important;
    overflow: hidden;
}

.ql-editor {
    overflow: hidden;
}

.p-editor-container .p-editor-toolbar {
    background-color: #353F4C;
}

.p-editor-container .p-editor-content .ql-editor {
    background-color: #353F4C;
}

.added_tags {
    display: flex;
    gap: 10px;
    margin-top: 20px;
}

.added-tag {
    padding: 12px 20px;
    border-radius: 20px;
    background-color: #353F4C;
}

.add-tag .col-12 {
    padding: 0;
}

.btn-add_tag {
    background-color: #353F4C;
    border: none;
}

.value-tag,
.select-filter {
    border: none;
    background-color: #353F4C;
    border-radius: 6px;
    height: 40px;
}

.filters-sent {
    margin-top: 37px;
}

.p-button.btn-add_filter,
.p-button.btn-add_filter:hover {
    height: 40px;
    background-color: var(--primary-color);
    color: var(--text-color);
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    padding: 12px 0;
    width: 258px;
    /*    margin-top: 30px; */
}

.btn-clear_filter,
.p-button.btn-clear_filter:hover {
    background-color: transparent;
    color: #FF3B30;
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    width: max-content;
    margin-top: 40px;
}

.btn-plus_tags {
    cursor: pointer;
    color: var(--primary-color) !important;
}

.tag-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    padding: 12px 20px;
    border-radius: 20px;
    background-color: #FFFFFF1A;
    border: none;
    width: max-content;
}

.list-tags {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    flex-wrap: wrap;
}

.input-add_tag,
.filter-select_funnel,
.filter-date {
    background-color: var(--surface-ground);
    border-radius: 4px;
    height: 50px;
    border: 1px solid #FFFFFF1A;
    margin-top: 10px;
}

.filter-select_funnel .p-button,
.filter-date .p-button {
    background-color: transparent;
    border: none;
}

.filter-select_funnel .p-button {
    color: var(--text-color);
}

.filter-date .p-button {
    color: var(--primary-color);
}

.filter-select_funnel .p-inputtext,
.filter-date .p-inputtext {
    border: none;
}

.input-add_tag input {
    border: none;
    height: 100%;
}

.mailing-btns_actions button {
    width: 145px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
}

.mailing-btns_actions .btn-send {
    background-color: var(--primary-color);
    border: none;
}

.mailing-btns_actions .p-button.p-button-text.btn-save {
    background-color: var(--surface-ground);
    border: 1px solid var(--bg-button);
}

.mailing-btns_actions .p-button.p-button-text.btn-cancel {
    background-color: var(--bg-button);
    border: none;
}

.scroll-mailing .p-scrollpanel-content {
    flex-direction: column;
}

.scroll-mailing {
    height: calc(100vh - 15rem);
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (min-width: 1200px) {

    .scroll-mailing {
        height: calc(100vh - 21rem);
    }

    .diagram-col {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }

    .dropdown-col {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }

}

@media screen and (min-width: 1600px) {

    .diagram-col {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 33.3333%;
    }

    .dropdown-col {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 33.3333%;
    }

}



@media screen and (max-width: 1330px) {

    .mailing-btns_actions .p-button.p-button-text.btn-save,
    .mailing-btns_actions .p-button.btn-send {
        width: 50%;
    }

    .mailing-btns_actions .p-button.p-button-text.btn-cancel {
        width: 33%;
    }

}