.toolbar-sidebar {
    width: 350px !important;
    position: relative;
}

.new-node-container {
    // border: 1px solid red; 
    // box-sizing: content-box; 
    height: 100%;
    color: var(--text-color);
    background: var(--surface-card);
    font-size: 12px;
    padding-bottom: 150px !important;

    &_content {
        // border: 1px solid white;
        height: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #3f4b5b;
            // background-color: #007AFF;
            border-radius: 6px;
            // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
    }

    .sidebar-buttons {
        // border: 1px solid red;
        border-top: 1px solid #78909C;
        background: var(--surface-card);
        position: absolute;
        z-index: 9999;
        padding: 16px;
        height: 150px;
        left: 0;
        right: 0;
        bottom: 0;

        &.close {
            height: auto;
        }
    }

    .header {
        // border: 1px solid blue;
        // border-bottom: 1px solid #545B67;
        padding: 20px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-info {
            display: flex;
        }

        &-info svg {
            margin-right: 20px;
        }

        &-info h2 {
            color: var(--text-color);
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            margin: 0;
        }

        .button-close {
            color: var(--text-color);

            span {
                font-size: 14px;
            }

            &:hover {
                color: var(--text-color);
            }
        }
    }

    h2 {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
    }

    .block {
        padding: 12px 10px !important;
    }

    .block-type {
        font-size: 16px;
    }

    h4,
    h5 {
        color: #78909C;
        font-size: 14px;
        margin-bottom: 8px;
    }

    h3 {
        font-size: 14px;
    }

    p {

        font-size: 14px;
        line-height: 16px;
    }

    label {
        font-size: 14px;
    }

    .select,
    .text,
    .text-area {
        border: 1px solid #78909C;
        font-size: 14px;
        background: transparent;
        color: var(--text-color);
        height: 50px;
    }

    .p-dropdown {
        height: 50px;
    }

    .p-dropdown .p-dropdown-label {
        line-height: 30px;
        font-size: 14px;
    }

    .p-dropdown-panel .p-dropdown-header {
        padding: 10px 15px;
    }

    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
        height: 30px;
        width: 100%;
    }

    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        font-size: 14px !important;
        padding: 10px 15px;
    }

    .p-dropdown-trigger-icon {
        font-size: 10px !important;
    }

    /* Кнопки */
    .btn,
    .btn-40 {
        height: 50px;
        font-size: 14px;
        background: #007aff;
        border: none;
        color: #fff;
        margin-bottom: 10px;
    }

    .btn-40 {
        height: 40px;
        padding: 5px;
    }

    .btn-publicate {
        background: #28cd41;
    }

    .btn-cancel {
        font-size: 14px;
        text-decoration: none;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }

    /* Calendar */
    .delay-calendar {
        height: 50px;

    }

    .p-calendar-w-btn-right .p-inputtext {
        border: 1px solid #78909C;
        background: transparent;
        border-right: none;
        font-size: 14px;
    }

    .p-calendar-w-btn-right .p-datepicker-trigger,
    .p-calendar-w-btn-right .p-datepicker-trigger:hover {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background: transparent;
        color: #007AFF;
        cursor: auto;
        border: 1px solid #78909C;
        border-left: none;
    }


    .p-calendar-w-btn-right.disabled-btn .p-datepicker-trigger,
    .p-calendar-w-btn-right.disabled-btn .p-datepicker-trigger:hover {
        cursor: default !important;
        pointer-events: none;
    }


    .select {
        border: 1px solid #78909C;
        background: transparent;
        font-size: 14px !important;
        height: 50px;
    }

    .text {
        height: 50px;
    }

    .text-area {
        height: 50px;
        padding: 12px 10px;
    }

    .p-checkbox .p-checkbox-box {
        background: transparent;
    }

    .p-checkbox .p-checkbox-box:hover {
        background: transparent !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
        color: #28CD41;
        font-size: 14px;
    }

    /* Accordion */
    .p-accordion {
        border-bottom: 1px solid #78909C;
    }

    .accordion-header {
        font-size: 14px;
        font-weight: 700;
        border-bottom: none;
    }

    .p-accordion-header .p-accordion-header-link {
        background: var(--surface-card);
        border-radius: 0px !important;
        border: none;
        border-top: 1px solid #78909C !important;
        padding: 20px 16px;
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
        background: var(--surface-card);
    }

    .p-accordion-header .p-accordion-header-link:focus {
        box-shadow: none !important;
    }

    .accordion-header a {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        border-bottom: none;
    }

    .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
        font-size: 12px;
        color: #78909C;
    }

    .p-accordion-content {
        background: var(--surface-card);
        border: none;
        /* border-bottom: 1px solid #545B67; */
        padding: 0 16px !important;
    }

    .attach {
        display: flex;
        flex-direction: column;
    }

    /* .new-node-container  .btn-paperclip {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-weight: bold;
        width: 30px !important;
        height: 30px !important;
        border: none;
        background: #fff;
        border-radius: 6px;
        color: #007aff;
    } */


    /* Публикация */
    .publication,
    .variables-info {
        color: var(--text-color);
        padding-bottom: 60px !important;
    }

    .variables-info {
        // background: url('../public/assets/layout/images/funnels/variables-fon.svg') no-repeat bottom;
    }

    .publication {
        // background: url('./public/assets/layout/images/funnels/publication-fon.svg') no-repeat bottom;
    }

    .publication p,
    .variables-info p {
        width: 80%;
        color: var(--text-color);
        font-size: 14px;
        line-height: 16px;
    }

    /* Create Text-Block */
    .p-float-label {
        &>label {
            left: 10px;
        }

        input {
            &:focus~label {
                left: 10px;
                top: 0;
                background: var(--surface-card);
                padding: 0 5px;
            }

            &.p-filled~label {
                left: 10px;
                top: 0;
                background: var(--surface-card);
                padding: 0 5px;
            }
        }

        .text-area {
            &~label {
                top: 50%;
            }

            &:focus~label {
                left: 10px;
                top: 0;
                background: var(--surface-card);
                padding: 0 5px;
            }

            &.p-filled~label {
                left: 10px;
                top: 0;
                background: var(--surface-card);
                padding: 0 5px;
            }
        }

        .p-inputwrapper {
            &-focus~label {
                left: 10px;
                top: 0;
                background: var(--surface-card);
                padding: 0 5px;
            }

            &-filled~label {
                left: 10px;
                top: 0;
                background: var(--surface-card);
                padding: 0 5px;
            }
        }

    }

    .p-editor-container {
        border: 1px solid #78909C;
        border-radius: 6px;
    }

    .p-editor-container .p-editor-content .ql-editor {
        border-top: none;
        background: transparent;
    }

    .p-editor-container .p-editor-toolbar.ql-snow {
        background: transparent;
        /* border: 1px solid #78909C; */
        /* border-bottom:  none; */
    }

    .ql-toolbar.ql-snow+.ql-container.ql-snow {
        font-family: 'Century Gothic' !important;
    }

    .p-editor-container .p-editor-content .ql-editor::before {
        color: var(--text-color);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
    }

    .p-editor-container .p-editor-content .ql-editor p {
        font-size: 14px !important;
        color: var(--text-color);
    }

    .text-length {
        text-align: right;
        font-size: 12px;
        line-height: 13.50px;
    }

    .create-button-container {
        border-bottom: 1px solid #78909C;
    }
    .create-button-header {
        margin-bottom: 10px;
    }
    .create-button-header,
    .create-action-header,
    .create-channel-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .create-button-header h5,
    .create-channel-header h5,
    .create-action-header h5 {
        color: #007AFF !important;
        font-weight: 700;
        margin-bottom: 0;
    }

    .create-button-header .button-delete,
    .create-channel-header .button-delete,
    .create-action-header .button-delete,
    .create-button-header .button-delete:focus,
    .create-channel-header .button-delete:focus,
    .create-action-header .button-delete:focus {
        box-shadow: none;
        border: none;
        background: transparent;
        color: #FF3B30;
    }


    /* Загрузка файлов в текстовый блок */
    .p-fileupload-content>.p-progressbar {
        // display: none;
    }

    .p-fileupload .p-fileupload-content {
        position: relative;
        border: none;
        width: 100%;
        padding: 20px 0;
    }

    .p-fileupload .p-fileupload-content .delete-file {
        color: #FF3B30;
    }

    .p-button.p-fileupload-choose {
        border: 1px solid red;
        height: 30px;
        width: 30px;
        font-size: 10px;
        border: none;
        color: #007aff;
        background: #fff;
    }

    .media-items {
        font-size: 14px;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .media-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        margin: 5px 0 5px 0;
        svg, i {
            color: var(--text-color);
            margin-right: 10px;
        }
        svg {
            fill: var(--text-color)
        }
    }

    .media-item img {
        color: red;
        fill: red;
        margin-right: 10px;
    }

    .media-item .button-delete {
        box-shadow: none;
        border: none;
        background: transparent;
        width: 20px;
        height: 20px;
        color: #FF3B30;
    }

    /* Загрузка файлов в блок формы */
    .form-block .p-button.p-fileupload-choose {
        width: 30px;
        height: 30px;
        font-size: 10px;
        border: none;
        color: #007aff;
        background: #000;
    }

    .qr-container {
        border-radius: 6px;
        overflow: hidden;
    }

    .form-draganddropfile {
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        border: 1px dashed #007aff;
        background: var(--surface-ground);
        width: 100%;
        height: 120px;
        border-radius: 6px;
        // padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        color: var(--text-color);
        position: relative;
    }

    .form-draganddropfile.drag {
        border: 1px dashed #9747ff;
    }

    .form-draganddropfile i {
        font-size: 16px;
        border-radius: 50%;
        color: var(--text-color);
        background: none;
        padding: 10px;
        margin-top: 0;
    }

    .form-block .form-draganddropfile span {
        color: var(--text-color);
        margin: 0;
        font-size: 14px;
        text-align: center;
    }

    .website-form-display {
        border: 1px solid #78909C;
        color: #78909C;
        border-radius: 6px;
        padding: 10px;
        margin-bottom: 16px;
        font-size: 14px;
        height: auto;
    }

    .p-fileupload .p-fileupload-row>div {
        width: 100% !important;
        justify-content: space-between !important;
        padding: 10px 0;
    }

    .settings-switch {
        margin-right: 12px;
    }

}

/*=========== Переменные ===========*/
/* .variables .reset {
    font-size: 14px;
    color: #fff;
    background: rgba(0, 122, 255, 0.1);
    border: 1px solid #007AFF;
    border-radius: 6px;
} */
.variables {
    &-list-header {
        padding: 15px 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #78909C;
        font-size: 14px;
    }

    .variable-item {
        /* border: 1px solid red; */
        font-size: 16px;
        border-bottom: 1px solid #78909C;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        min-height: 60px;

        .variable-container {
            width: 80%;
            display: flex;
        }

        .variable-info {
            /* display: flex;
            justify-content: space-between; */
            margin-bottom: 10px;
            width: 60%;

            .variable-name {
                padding-right: 5px;
                font-weight: 700;
                color: #007AFF;
                margin-bottom: 5px;
                line-height: 13.50px;
                word-wrap: break-word;
            }

            .variable-type {
                font-size: 14px;
                text-align: left;
            }
        }

        .variable-values {
            width: 40%;
            display: flex;
            justify-content: space-between;

            h5 {
                font-weight: 700;
                font-size: 14px;
                line-height: 13.50px;
                margin-bottom: 5px;
            }
        }

        .variable-buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;

            .var-btn {
                background: none;
                border: none;
                width: 20px;
                height: 20px;
            }

            .var-btn.edit {
                color: #007AFF !important;
                margin-right: 5px;
            }

            .var-btn.delete {
                color: #FF3B30;
            }

            span {
                font-size: 16px;
            }
        }

    }

    .btn-cancel {
        text-decoration: none;
        background: transparent;
        border: 1px solid rgba(255, 255, 255, 0.1) !important;
    }
}



/* ScrollBar */

.new-node-container:-webkit-scrollbar {
    width: 5px;
}

.new-node-container::-webkit-scrollbar-track {
    background-color: darkgrey;
}