.used-bot {
    display: none;
    width: 300px;
    height: 120px;
    padding: 20px 30px;
    color: #fff;
    background: #2D3645;
    border: 1px solid #007AFF;
    border-radius: 20px;
    position: absolute;
    top: 100px;
    right: 70px;
    z-index: 999;
}
.used-bot_title {
    font-size: 14px;
    font-weight: 700;
}
.used-bot.p-dropdown-panel {
    z-index: 999 !important;
}
.select-bot {
    background: none;
    box-shadow: none;
}
.select-bot:focus {
    border: none;
}
.used-bot .pi-caret-down {
    color: #fff;
    font-size: 12px;
}