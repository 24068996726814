.Onboarding {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(84, 91, 103, 0.5);
    backdrop-filter: blur(12px);
    z-index: 999999;
    overflow: hidden;
}

.card-onboard {
    width: 660px;
    height: 650px;
    border-radius: 30px;
    background-color: var(--surface-card);
    border: 1px solid var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 50px;
}

.img-onboard {
    display: flex;
    justify-content: center;
    margin-top: -75px;
    margin-bottom: 70px;
}

.img-onboard img {
    width: 100%;
}

.title-onboard_block {
    display: inline;
}

.title-onboard {
    font-size: 45px;
    font-weight: 700;
    line-height: 45px;
    text-align: left;

}

.title-onboard span {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: -10px;
}

.main-text_onboard {
    font-size: 32px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}

.buttons button {
    height: 40px;
    padding: 10px 40px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.btn-skip,
.p-button.btn-skip:hover {
    background-color: transparent;
    border: 1px solid var(--text-color);
    color: var(--text-color);
}

.btn-proceed,
.p-button.btn-proceed:hover,
.btn-close,
.p-button.btn-close:hover {
    background-color: var(--text-color);
    border: none;
}

.video-onboard {
    border-radius: 30px;
    position: relative;
}

.text-block {
    position: absolute;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 130px;
}

.text-onboard {
    font-size: 36px;
    font-weight: 700;
    line-height: 36px;

}

.btn-play {
    position: absolute;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    cursor: pointer;
}



.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    width: 100%;
}

.video-container #myvideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 30px;
}

.video-container .buttons {
    width: 100%;
    position: absolute;
    z-index: 1;
    padding: 40px;
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1300px) {
    .text-onboard {
        font-size: 32px;
        line-height: 32px;
    }

    .btn-play {
        width: 160px;
        height: 160px;
        top: calc(50% - 80px);
        left: calc(50% - 80px);
    }
}

@media screen and (max-width: 1000px) {
    .text-block {
        top: 100px;
    }

    .text-onboard {
        font-size: 26px;
        line-height: 26px;
    }

    .btn-play {
        width: 120px;
        height: 120px;
        top: calc(50% - 60px);
        left: calc(50% - 60px);
    }
}

@media screen and (max-width: 768px) {
    .card-onboard {
        width: 100%;
    }

    .video-container .buttons {
        padding: 20px;
    }

    .text-block {
        top: 70px;
    }

    .text-onboard {
        font-size: 20px;
        line-height: 20px;
    }

    .btn-play {
        width: 90px;
        height: 90px;
        top: calc(50% - 45px);
        left: calc(50% - 45px);
    }
}

@media screen and (max-width: 560px) {
    .video-container .buttons {
        padding: 12px;
    }

    .video-container .buttons button {
        height: 32px;
        padding: 10px 20px;
    }

    .btn-play {
        width: 80px;
        height: 80px;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
    }
}

@media screen and (max-width: 490px) {
    .text-onboard {
        font-size: 16px;
        line-height: 16px;
    }

    .text-block {
        top: 50px;
    }
}