.preview {
    padding: 30px;
}

.header-view_answer {
    border-bottom: 1px solid #353F4C;
    margin-top: -30px;
    margin-left: -30px;
    margin-right: -16px;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

p.title-header {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-color);
    margin-top: 20px;
    margin-bottom: 20px;
}

.label-switch {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: right;
    color: var(--text-color);
    margin-left: 10px;
}

.p-button.btn-edit_answer,
.p-button.btn-edit_answer:hover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.p-button.btn-trash_answer,
.p-button.btn-trash_answer:hover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #EF3E361A;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.title-answer {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 0;
}

.text-answer {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    margin: 20px 0;
}

.text-and {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 10px 0 10px 20px;
}

.switch-block {
    margin-top: 40px;
}

.create-smart_answer {
    color: #36EF54;
}

.expand_messages {
    color: #69B7FF;
}

.remove {
    color: #EF3E36;
}

.p-datatable .p-datatable-tbody>tr {
    background-color: transparent;
}

.p-datatable .p-datatable-tbody>tr>td {
    border: none;
}

.p-datatable .p-datatable-tbody>tr>td p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.p-datatable .p-datatable-tbody>tr>td a {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: underline;
}

.p-datatable .p-datatable-thead>tr>th {
    border: none;
    background-color: transparent;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--primary-color);
}

.wrapper-messange {
    background-color: var(--surface-ground);
    border-radius: 6px;
    margin-left: 20px;
    padding: 10px 20px 30px 20px;
}

.wrapper-funnel {
    background-color: #36EF541A;
    border-radius: 6px;
}

.funnel-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #28CD41;
}

.answer-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #566476;
}

.answer-time {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #36EF54;
}

.answer-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.answer-number {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: var(--primary-color);
}

.card-time {
    border-radius: 6px;
    background-color: var(--surface-ground);
    margin: 20px 0 80px 0;
    padding-top: 30px;
    padding-bottom: 20px;
}

.card-time h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

}

.select-time_input {
    width: 44px;
    height: 60px;
    border: 1px solid var(--surface-border);
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.p-scrollpanel.new_answer-scroll .p-scrollpanel-wrapper {
    border-right: 10px solid var(--surface-ground);
    border-radius: 6px;
}

.p-scrollpanel.new_answer-scroll .p-scrollpanel-bar {
    background-color: var(--primary-color);
    opacity: 1;
    border-radius: 6px;
}

.p-scrollpanel.new_answer-scroll .p-scrollpanel-bar-x {
    display: none;

}

.p-scrollpanel.new_answer-scroll .p-scrollpanel-content,
.smart-answer_scroll .p-scrollpanel-content,
.smart-answer_scroll_2 .p-scrollpanel-content {
    display: flex;
    flex-direction: column;
}

.btn-arrow,
.p-button.btn-arrow:hover {
    background-color: transparent;
    border: none;
}

.btn-arrow i {
    color: var(--text-color);
}

.small-text {
    color: #545B67;
}

.button-add {
    height: 40px;
    background-color: #353F4C;
    border-radius: 6px;
    border: none;
    color: #F7FFF7;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    display: flex;
    justify-content: center;
}

.p-invalid.title-answer_input {
    margin-bottom: 5px;
}

.action-block {
    margin-top: 14.5px;
}

.title-answer_input {
    height: 50px;
    background-color: #353F4C;
    font-size: 26px;
    font-weight: 400;
    line-height: 32px;
    color: var(--text-color);
    border: 1px solid #FFFFFF1A;
    margin-bottom: 36px;
}

.title-answer_input::placeholder {
    color: #FFFFFF;
}

.title-answer_input.dark,
.select-time_input.dark {
    border: 1px solid #FFFFFF1A;
}

.title-answer_input.light,
.select-time_input.light {
    border: 1px solid #545B67;
}

.smart-answer_scroll_2 {
    height: calc(100vh - 24.1rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.smart-answer_scroll_2.sidebar {
    height: calc(100vh - 18rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.edit-answer_scroll {
    height: calc(100vh - 24rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.edit-answer_scroll .p-scrollpanel-content {
    flex-direction: column;
}

.p-invalid.new-answer_textarea {
    border-color: #f19ea6;
    margin-bottom: 5px;
}

.p-invalid.funnel_selection,
.p-invalid.attach-bot_select {
    border-color: #f19ea6;
    margin-bottom: 5px;
}

.p-invalid.funnel_selection button.p-button,
.p-invalid.funnel_selection button.p-button:hover,
.p-invalid.attach-bot_select button.p-button,
.p-invalid.attach-bot_select button.p-button:hover {
    border-color: #f19ea6;
}

.button-save {
    height: 40px;
    background-color: #69B7FF;
    border-radius: 6px;
    border: none;
    color: #566476;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    justify-content: center;
}

.card-answer_text {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.create-answer,
.p-button.create-answer:hover {
    background-color: #28CD411A;
    border: 1px solid #28CD41;
    color: var(--text-color);
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
    background: var(--surface-ground);
    color: var(--text-color)
}

.p-button.p-button-text.no_active-btn {
    color: #566476;
    text-align: start;
}

.p-button.p-button-text.active-btn .p-button-label {
    color: #FFFFFF;
    text-align: start;
}

.p-button.p-button-text.active-btn .p-button-icon {
    color: #69B7FF;
}

.p-button.p-button-text.maximize,
.p-button.p-button-text.maximize:hover {
    color: var(--text-color)
}

.btn-sort_date {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #FFFFFF1A;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sort-smart_answer {
    background-color: #007AFF1A;
}

.sort-smart_answer .settings-switch.dark span.p-inputswitch-slider {
    background-color: #353F4C;
}

.card.additional_settings {
    height: 220px;
    border-radius: 20px;
    border: 1px solid #FFFFFF1A;
    background-color: transparent;
}

.card.additional_settings p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #545B67;
}

.new-answer_input {
    border: 1px solid var(--surface-border);
    height: 50px;
    border-radius: 6px;
    background-color: #353F4C;
    margin-right: 22px;
}

.new-answer_textarea {
    border: 1px solid var(--surface-border);
    border-radius: 6px;
    background-color: #353F4C;
    margin: 20px 0 50px 0;
    height: 100px;
    padding-bottom: 10px;
}

.new-answer_textarea textarea {
    background-color: transparent;
    border: none;
    padding: 20px;
    height: 63px !important;
}

.new-answer_textarea textarea.p-inputtextarea.p-inputtext.p-component.p-inputtextarea-resizable:focus {
    box-shadow: none;
}


.add-message,
.p-button.add-message:hover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #28CD411A;
    border: none;
}

.messages_from-user {
    background-color: var(--surface-ground);
    border-radius: 20px;
    padding: 4px 0 4px 12px;
    align-items: center;
}

.text-messages_user {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
}

.p-button.btn-delete_tag,
.p-button.btn-delete_tag:hover {
    background-color: transparent;
    border: none;
}

.p-button.p-button-text.btn-cancel,
.p-button.p-button-text.btn-cancel:hover {
    border: 1px solid var(--surface-border)
}

.p-button.p-button-text.btn-cancel span {
    color: var(--text-color)
}

.funnel_selection {
    margin: 20px 0 10px 0;
}

.funnel_selection input {
    border-right: none;
    border-top: 1px solid var(--surface-border);
    border-bottom: 1px solid var(--surface-border);
    border-left: 1px solid var(--surface-border);
    background-color: transparent;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    height: 50px;
    background-color: #353F4C;
}

.attach-bot_select {
    background-color: #353F4C;
    border-radius: 6px;
    min-height: 50px;
    width: 100%;
    height: auto;
}

.bot-item-value {
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: 0.5rem;
    background-color: var(--primary-color);
    color: var(--text-color);
}

.p-multiselect-label.p-multiselect-items-label {
    display: flex;
    gap: 10px;
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
}

.p-multiselect-label-container {
    display: flex;
    align-items: center;
}

.funnel_selection button.p-button,
.funnel_selection button.p-button:hover,
.attach-bot_select button.p-button,
.attach-bot_select button.p-button:hover {
    background-color: transparent;
    border-right: 1px solid var(--surface-border);
    border-top: 1px solid var(--surface-border);
    border-bottom: 1px solid var(--surface-border);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: none;
    color: var(--text-color);
    background-color: #353F4C
}

.list-messages {
    margin-top: 10px;
    gap: 10px;
}

.attach-bot .switch-block {
    margin-bottom: 12px;
}

.title-condition {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.condition-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-color);
}

.funnel-text {
    color: #28CD41;
}

.block-buttons_edit {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--surface-ground);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block-buttons_edit:hover {
    opacity: 1;
}

.add-answer {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--surface-ground);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.p-button.p-button-outlined.textarea-smile {
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textarea-smile svg {
    fill: var(--surface-border) !important;
}

.add-answer svg {
    fill: var(--text-color)
}

.add-answer-btn,
.p-button.add-answer-btn:hover {
    background-color: transparent;
    border: none;
    color: var(--text-color);
}

.add-answer-btn.primary,
.p-button.add-answer-btn.primary:hover {
    background-color: var(--primary-color);
    border: none;
    color: var(--text-color);
    height: 50px;
    border-radius: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.called-answers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
}

.wrapper-text {
    background-color: var(--surface-ground);
    /* background-color: #353F4C; */
    padding: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
}


.block-called_answers {
    width: 21%;
}

.block-select_date {
    width: 37%;
    /*     margin-right: 30px; */
}

.block-diagram {
    width: 37%;
    /*     margin-right: 30px; */
}

.block-diagram .CardDiagramMain {
    width: 100%;
}

.smart-answer_table tbody tr {
    border-bottom: none
}

.smart-answer_table tbody tr td:last-child {
    margin-bottom: 10px;
}

.smart-answer_table tbody tr td:first-child {
    margin-top: 10px;
}

.smart-answer_table tbody tr td span {
    margin-right: 10px;
}

.smart-answer_table tbody tr td span:last-child {
    margin-right: 0;
}

.smart-answer_table tbody tr td:nth-child(2) span:nth-child(2) {
    margin-left: auto;
}

@media screen and (max-width: 750px) {
    .smart-answer_table tbody tr {
        border-bottom: 0.5px solid var(--surface-border);
    }
}

.img {
    fill: var(--text-color);
}

.chats div svg {
    fill: var(--surface-ground);
}

.p-sidebar-right .p-sidebar.popover {
    width: 30rem;
    border-radius: 20px;
    border: none;

}

.text-no_selected {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    width: 255px;
    color: var(--text-color);
}

.warning-message {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #545B67;
}

.messages_user-block {
    margin-bottom: 40px;
    margin-top: 20px;
}

.add-tag_button {
    background-color: transparent;
    border: none;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    text-decoration: underline;
    color: var(--primary-color);
    width: max-content;
    cursor: pointer;
    margin: 20px auto 35px 0;
}

.bot-item {
    display: flex;
}

.bot-item img {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1460px) {
    .btn-arrow {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .btns-block .btn-cancel,
    .btns-block div .btn-save {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .btns-block div .btn-main {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}

@media screen and (max-width: 1330px) {
    .btn-arrow {
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
}

.multiselect-demo .p-multiselect {
    min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
    padding-top: .25rem;
    padding-bottom: .25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
    padding: .25rem .5rem;
    border-radius: 3px;
    display: inline-flex;
    margin-right: .5rem;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
    width: 17px;
}

@media screen and (max-width: 1600px) {
    .time-block {
        width: 25%;
    }
}

@media screen and (min-width: 830px) {

    .statistics-right_col {
        flex-direction: row !important;
        padding: 0 !important;
    }

    .statistics-date {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
        margin-bottom: 0.5rem !important;
    }

    .statistics-users {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }


}

@media screen and (min-width: 1200px) {

    .statistics-right_col {
        flex-direction: row !important;

    }

    .left-column {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 58.333%;
    }

    .right-column {
        display: block !important;
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 41.6667%
    }

    .statistics-date {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 58.333%;
    }

    .statistics-users {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 41.6667%;
    }

}

@media screen and (max-width: 1600px) {

    .block-diagram {
        width: 36%;
    }

    .block-select_date {
        width: 36%;
    }

    .block-called_answers {
        width: 26%;
    }
}

@media screen and (max-width: 1565px) {

    .block-diagram {
        width: 38%;
    }

    .block-select_date {
        width: 34%;
    }

    .block-called_answers {
        width: 26%;
    }
}

@media screen and (max-width: 1490px) {
    .block-diagram {
        width: 36%;
    }

    .block-select_date {
        width: 34%;
    }

    .wrapper-text {
        padding: 10px;
    }
}

@media screen and (max-width: 1199px) {
    .block-diagram {
        width: 37%;

    }

    .block-select_date {
        width: 37%;

    }

    .block-called_answers {
        width: 21%;

    }
}

@media screen and (max-width: 1100px) {
    .block-diagram {
        width: 36%;
    }

    .block-select_date {
        width: 36%;
    }

    .block-called_answers {
        width: 26%;
    }
}

@media screen and (max-width: 750px) {
    .block-diagram {
        width: 100%;
    }

    .block-select_date {
        width: 100%;
    }

    .block-called_answers {
        width: 100%;
    }

    .wrapper-text {
        margin-bottom: 10px;
    }

    .wrapper-text span {
        width: 100%;
        text-align: center;
    }
}

@media screen and (min-width: 1430px) {

    .statistics-date,
    .statistics-users {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }
}

@media screen and (min-width: 1600px) {

    .statistics-left_col {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 58.333%;
    }

    .statistics-right_col {
        flex-direction: column !important;
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 41.6667%;
        padding: 0.5rem !important
    }

    .left-column {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 66.6667%;
    }

    .right-column {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 33.3333%
    }

    .statistics-date,
    .statistics-users {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 100%;
    }

    .block-diagram {
        width: 37%;
    }

    .block-select_date {
        width: 37%;
    }

    .block-called_answers {
        width: 21%;
    }
}