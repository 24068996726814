.chats,
.preview {
    height: calc(100vh - 8rem);
    overflow: auto;
}

.chats.fixed {
    width: 32%;
}


.active_chat {
    background-color: var(--surface-ground);
    border-radius: 20px;
}


.chats-list li:hover {
    cursor: pointer;
}

.write_message input {
    border: none;
    outline: none;
    background-color: transparent;
    border-radius: 20px 0 0 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--text-color);
}

.write_message input:focus {
    border: none;
    outline: none;
    background-color: transparent;
}

.write_message {
    height: 60px;
    border-radius: 20px 20px 0px 20px;
    display: flex;
    align-items: center;
}

.btn-send_message {
    width: 60px;
    height: 60px;
    background-color: #276FB2;
    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.write_message.dark {
    background-color: #FFFFFF1A;
}

.write_message.light {
    background-color: #FFFFFF;
}

.p-button-outlined.btn-paperclip {
    background-color: transparent;
    border: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
}

.btn-paperclip svg {
    fill: var(--text-color) !important;
    fill-opacity: 0.1;
}

.wrapper-date p {
    color: #566476;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.wrapper-date {
    border-radius: 6px;
    height: 40px;
    background-color: var(--surface-ground);
}



ul.chats-list {
    list-style-type: none;
}

.chats-list li:hover {
    border-radius: 20px;
    background-color: var(--surface-ground);
}



.status {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;

}

.status::before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    vertical-align: middle;
    border-radius: 50%;
    background-color: #EF3E36;
    margin-right: 5px;
}

.no_answer {
    background-color: #EF3E361A;
    color: #EF3E36;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.in_touch {
    background: #36EF541A;
    color: #28CD41;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.message-from {
    background-color: #566476;
    border-radius: 20px 20px 20px 0;
    padding: 10px 20px;
}

.message-to {
    background-color: #276FB2;
    border-radius: 20px 20px 0 20px;
    padding: 10px 20px;
    min-width: 220px;
    max-width: 340px;
}

.no_info {
    background-color: #353F4C;
    color: #566476;
}

.communication_methods {
    padding: 10px 13px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}


.gray {
    color: #566476;
}

.sistem-message {
    background-color: #007AFF1A;
    border-radius: 20px 20px 0 20px;
    padding: 20px;
}

.block-chat {
    background-color: #EF3E361A;
    color: #EF3E36;
    border-radius: 6px;
    text-align: center;
}

.p-timeline .p-timeline-event-marker {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #007AFF;
    background-color: transparent;
}

.p-timeline .p-timeline-event-connector {
    background-color: #007AFF;

}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
    width: 1px;
}

.p-timeline-event {
    min-height: 50px;
}

.date-messange {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.message-list {
    height: calc(100vh - 15rem);
    overflow: auto;
}

.funnel-scroll {
    height: calc(100vh - 24rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.funnel-scroll.sidebar {
    height: calc(100vh - 18rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.list-contact {
    height: calc(100vh - 14rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar {
    height: calc(100vh - 10rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar-new_answer {
    height: calc(100vh - 20rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.smart-answer_scroll.sidebar {
    height: calc(100vh - 16rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.smart-answer_scroll {
    height: calc(100vh - 22.1rem);
    overflow-y: auto;
    overflow-x: hidden;
}


.view-smart_answer {
    height: calc(100vh - 19rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.view-smart_answer.sidebar {
    height: calc(100vh - 13rem);
    overflow-y: auto;
    overflow-x: hidden;
}


.p-scrollpanel-content::-webkit-scrollbar,
.message-list::-webkit-scrollbar,
.smart-answer_scroll::-webkit-scrollbar,
.smart-answer_scroll_2::-webkit-scrollbar,
.card.chats::-webkit-scrollbar,
.card.preview::-webkit-scrollbar,
.funnel-scroll::-webkit-scrollbar {
    width: 0;
}

.p-scrollpanel-content {
    display: flex;
}

.full-height {
    height: calc(100vh - 8rem);
}