.demo-mode {
    z-index: 9999;
    position: absolute;
    right: 25px;
    bottom: 25px;
    width: 635px;
    height: 840px;
    background: #2d3645;
    border-radius: 6px;
    padding: 20px;
}
