.p-button.p-component.calendar-block {
    border-radius: 20px;
    background-color: #545B67;
    border: 1px solid #FFFFFF1A;
    padding: 20px;
    border: none;
}

.p-button.p-component.calendar-block.active {
    background-color: var(--primary-color);
    box-shadow: none;
    font-weight: 700;
}

.calendar-block p {
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    color: #FFFFFF;
}

.calendar-block p.selected-date {
    font-size: 19px;
    font-weight: 400;
    line-height: 20px;
}


.date,
.p-button.date:hover {
    background-color: #545B67;
    border-radius: 20px;
    padding: 25px 23px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-color);
    border: 1px solid #FFFFFF1A;
    display: flex;
    justify-content: center;
}

.date.skeleton {
    background-color: var(--surface-card);
    border: none;
}


.light .date,
.light .p-button.date:hover {
    background-color: #EEF0F2;
    border-radius: 20px;
    padding: 5px 23px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #293241;
    border: none;
    display: flex;
    justify-content: center;
}

.calendar {
    position: absolute;
    z-index: 1;
}

.calendar p.selected-date {
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
}

.date.active,
.p-button.date.active:hover {
    background-color: var(--primary-color);
    box-shadow: none;
    font-weight: 700;
}

@media screen and (max-width: 1665px) {

    .calendar-block p.selected-date {
        font-size: 16px;
    }
}

@media screen and (max-width: 1500px) {
    .date {
        font-size: 14px;
    }

    .calendar-block p.selected-date {
        font-size: 14px;
    }

}

@media screen and (max-width: 1200px) {

    .calendar-block p.selected-date {
        font-size: 16px;
    }

    .date {
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {

    .calendar-block p.selected-date {
        font-size: 19px;
    }

}

.card-celect_date {
    border: none;
    background-color: var(--surface-card);
    border-radius: 20px;
    padding: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
}

.card-celect_date .p-button {
    width: 25%;
    background-color: transparent;
    border: 1px solid var(--surface-border);
    height: 40px;
    margin-top: 24px;
    color: var(--text-color);
}

.card-celect_date .p-button.p-highlight {
    background-color: var(--primary-color);
}

.card-celect_date .p-buttonset .p-button:last-of-type {
    border-radius: 0 6px 6px 0;
}

.card-celect_date .p-buttonset .p-button:first-of-type {
    border-radius: 6px 0 0 6px;
}

.select-date {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

/* .select-date .p-button {
    width: 25%;
} */

.card-celect_date .p-button.btn-calendar {
    background-color: #78909C;
    border-radius: 6px;
    border: none;
    width: auto;
    color: var(--text-color);
    height: 40px;
    margin-top: 30px;
}

.card-celect_date .p-button.btn-calendar.active {
    background-color: var(--primary-color);
}

@media screen and (max-width: 1600px) {
    .block-select_date .select-date .p-button {
        width: 47%;
        margin-top: 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:last-of-type {
        border-radius: 6px
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:first-of-type {
        border-radius: 6px
    }

    .block-select_date .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 6px
    }

    .block-select_date .p-buttonset .p-button:not(:last-child) {
        border: 1px solid var(--surface-border);
    }

    .block-select_date .select-date {
        gap: 10px;
    }
}

@media screen and (max-width: 1199px) {
    .block-select_date .select-date .p-button {
        width: 25%;
        margin-top: 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:last-of-type {
        border-radius: 0 6px 6px 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:first-of-type {
        border-radius: 6px 0 0 6px;
    }

    .block-select_date .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 0
    }

    .block-select_date .p-buttonset .p-button:not(:last-child) {
        border: 1px solid var(--surface-border);
    }

    .block-select_date .select-date {
        gap: 0;
    }
}

@media screen and (max-width: 1100px) {
    .block-select_date .select-date .p-button {
        width: 47%;
        margin-top: 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:last-of-type {
        border-radius: 6px
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:first-of-type {
        border-radius: 6px
    }

    .block-select_date .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 6px
    }

    .block-select_date .p-buttonset .p-button:not(:last-child) {
        border: 1px solid var(--surface-border);
    }

    .block-select_date .select-date {
        gap: 10px;
    }
}

@media screen and (max-width: 750px) {
    .block-select_date .select-date .p-button {
        width: 25%;
        margin-top: 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:last-of-type {
        border-radius: 0 6px 6px 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:first-of-type {
        border-radius: 6px 0 0 6px;
    }

    .block-select_date .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 0
    }

    .block-select_date .p-buttonset .p-button:not(:last-child) {
        border: 1px solid var(--surface-border);
    }

    .block-select_date .select-date {
        gap: 0;
    }
}

@media screen and (max-width: 460px) {
    .block-select_date .select-date .p-button {
        width: 47%;
        margin-top: 0;
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:last-of-type {
        border-radius: 6px
    }

    .block-select_date .card-celect_date .p-buttonset .p-button:first-of-type {
        border-radius: 6px
    }

    .block-select_date .p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 6px
    }

    .block-select_date .p-buttonset .p-button:not(:last-child) {
        border: 1px solid var(--surface-border);
    }

    .block-select_date .select-date {
        gap: 10px;
    }
}