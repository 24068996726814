.card {
    background-color: var(--surface-card);
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: $borderRadius;
    box-shadow: 0px 10px 40px rgb(41 50 65 / 6%) !important;

    &.white {
        background-color: #F7FFF7;
        color: #2D3645;
    }

    &.card-w-title {
        padding-bottom: 2rem;
    }
}