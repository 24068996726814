.title-card {
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
}

.title-card_statistics {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.statistics h1 {
    font-size: 61px;
    font-weight: 700;
}

.p-badge.events-badge {
    border-radius: 20px;
    background-color: #566476;
    color: #F7FFF7;
    font-size: 10px;
    font-weight: 400;
}

li.blue {
    color: var(--primary-color);
}

li.blue .event-text.dark,
li.red .event-text.dark,
li.yellow .event-text.dark {
    color: #F7FFF7;
}

li.blue .event-text.light,
li.red .event-text.light,
li.yellow .event-text.light {
    color: #293241;
}

li.yellow {
    color: #FFE66D;
}



li.red {
    color: #EF3E36;
}




.utility {
    font-size: 36px;
    color: var(--primary-color);
}

.gray {
    color: #566476;
}

.card-funnel {
    /*   width: 170px; */
    height: 100px;
    border-radius: 12px;
    padding-top: 6px;
    padding-bottom: 12px;
    background-color: var(--surface-ground);
}

.card-funnel.active {
    border: 2px solid #FFFFFF
}

.card-funnel h6,
.card-funnel p {
    margin-left: 12px;
}

.card-funnel div {
    margin-left: 5px;
}

/* .card-funnel.dark {
    background-color: #353F4C;
}

.card-funnel.light {
    background-color: #FFFFFF;
} */



.card-funnel:hover {
    cursor: pointer;
}




.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
    margin: 0;
}

.сonversion {
    color: #EF3E36;
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
}

.p-button-outlined.max.dark {
    border: 1px solid #566476;
    color: #FFFFFF;
}

.p-button-outlined.max.light {
    border: none;
    color: #293241;
    background-color: #FFFFFF;
}

.p-chart.chart-mobile {
    width: 80px;
    height: 30px;
    margin-left: -8px;
}

.red {
    color: #EF3E36;
}

.panel-statistics {
    height: 230px;
    display: flex;
    /*    gap: 30px; */
    justify-content: space-between;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.CardDiagramMain {
    width: 24%;
    height: 100%;
    padding: 20px 50px 20px 20px;
    background-color: var(--surface-card);
    border-radius: 20px;
}

.CardDiagramMain svg {
    fill: var(--text-color);
}

.messanger-wrapper {
    width: 130px;
    padding: 5px 18px 5px 6px;
    border-radius: 6px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.messanger-wrapper.white-font {
    color: var(--text-color);
}

.messanger-wrapper.black-font {
    color: var(--surface-card)
}

.other-messangers {
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 0;
}

.block-users {
    width: 19%;
    height: auto;
}

.block-date {
    width: 29%;
    height: 100%;
}

.confirmation {
    background-color: #FFFFFF;
    border-radius: 20px;
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #000000;
    display: flex;
    align-items: center;
    padding: 24px;
    display: flex;
    justify-content: space-between;
}

.confirmation-mail {
    font-weight: 700;
    color: var(--primary-color);
}

.btn-resend,
.p-button.btn-resend:hover {
    background-color: var(--primary-color);
    border: none;
    border-radius: 8px;
    color: var(--text-color);
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.btn-change_mail,
.p-button.btn-change_mail:hover {
    background-color: transparent;
    border: none;
    border-radius: 8px;
    color: var(--primary-color);
    padding: 10px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.diagram {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.events-block {
    width: 29%;
}

.events-block .card {
    max-height: 430px;
}

.funnel-block {
    display: flex;
    justify-content: space-between;
    width: 69.5%;
}

.statistics-funnel {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.messangers-block {
    margin-left: 20px;
}

.name-messangers {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    margin-right: 6px;
}

.coverage-messangers {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
}

@media screen and (min-width: 1460px) {
    .text-btn {
        display: inline !important;
    }
}

@media screen and (max-width: 1460px) {
    .skeleton-block {
        display: none
    }

    .block-users,
    .CardDiagramMain,
    .block-date {
        width: 32%;
        margin-bottom: 10px;
    }

    .panel-statistics {
        height: auto;
    }


}

@media screen and (max-width: 1210px) {

    .events-block,
    .funnel-block {
        width: 49%;
    }
}

@media screen and (max-width: 1210px) {
    .skeleton-block {
        display: block
    }

    .block-users,
    .CardDiagramMain,
    .block-date {
        width: 49%;
        margin-bottom: 10px;
    }

    .funnel-block {
        flex-direction: column;
    }
}

@media screen and (max-width: 1050px) {
    .skeleton-block {
        display: none
    }
}

@media screen and (max-width: 850px) {

    .block-users,
    .block-date {
        order: -1;
    }

    .block-users {
        width: 39%;
    }

    .block-date {
        width: 59%;
    }


    .CardDiagramMain,
    .events-block,
    .funnel-block {
        width: 100%;
        margin-bottom: 10px;
    }

}

@media screen and (max-width: 700px) {

    .block-users,
    .block-date {
        width: 100%;
        margin-bottom: 10px;
    }

    .select-date .p-button {
        padding: 0;
    }

}

@media screen and (max-width: 768px) {
    .skeleton-block {
        display: block
    }
}