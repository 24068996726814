.Authorization {
    background-repeat: no-repeat;
    background-size: cover;
    padding-right: 120px;
    /*     min-height: 100vh; */
    background-position: center;
}

.Authorization.dark {
    background-image: url(../../../public/assets/layout/images/authorization.png);
}

.Authorization.light {
    background-image: url(../../../public/assets/layout/images/authorization.light.jpg);
}

.card-auth {
    padding: 32px 16px;
    background-color: var(--surface-card);
    border-radius: 20px;
    width: 446px;
}

.calc-height {
    height: calc(100vh - 5rem);
    ;
}

.card-auth input {
    border-radius: 6px;
    font-size: 15px;
    height: 50px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: var(--text-color);
}

.card-auth.dark input {
    border: 1px solid #FFFFFF;
}

.card-auth.light input {
    border: 1px solid #007AFF;
}

.error-margin {
    margin-bottom: 4px;
}

.default-margin {
    margin-bottom: 22px;
}

.card-auth input::-webkit-input-placeholder {
    color: var(--bg-button);
}

.title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;

}

.btn-back {
    background-color: transparent;
    border: none;
}

.btn-back svg path {
    fill: var(--text-color);
}

.logo {
    width: 60px;
    height: 19px;
}

.p-inputswitch.remember {
    height: 20px;
    width: 40px;
}

.remember-label,
.problems,
.no_account {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.sign_in,
.p-button.sign_in:hover {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: var(--text-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: 6px;
}


.p-inputswitch.remember {
    width: 40px;
    height: 20px;
}

.remember span.p-inputswitch-slider {
    border-radius: 36.5px;
    height: 20px;
    width: 40px;
    background: transparent;
    border: 0.5px solid #566476
}

.remember span.p-inputswitch-slider::before {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    left: 2px;
}

.p-button-outlined.login_yandex,
.p-button.p-button-outlined.login_yandex:hover {
    border: 1px solid #FFD60A;
    color: #F7FFF7;
    width: 175px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.p-button-outlined.login_vk,
.p-button.p-button-outlined.login_vk:hover {
    border: 1px solid var(--primary-color);
    color: #F7FFF7;
    width: 175px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.p-button.p-component.cancel-registration,
.p-button.p-component.cancel-registration:hover {
    background-color: var(--bg-button);
    border: none;
    border-radius: 6px;
    color: #F7FFF7;
    width: 160px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-button.p-component.next-step,
.p-button.p-component.next-step:hover {
    background-color: var(--primary-color);
    border: none;
    border-radius: 6px;
    color: #F7FFF7;
    width: 160px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}



.p-password-input {
    width: 100%;
    font-size: 15px;
}

.footer {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.registration-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.p-button-outlined.login_yandex svg,
.p-button-outlined.login_vk svg {
    fill: var(--text-color);
}

.timer-redirect {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: var(--primary-color);
}

@media screen and (max-width: 992px) {
    .Authorization {
        padding-right: 0
    }
}

@media screen and (max-width: 450px) {
    .Authorization .card-auth {
        padding: 40px 30px
    }

    .Authorization .btns-block a,
    .p-button.p-component.next-step,
    .p-button.p-component.next-step:hover {
        width: 48%;
    }

    .Authorization .btns-block a .p-button.p-component.cancel-registration,
    .p-button.p-component.cancel-registration:hover {
        width: 100%;
    }
}