.events-title_block {
    height: 60px;
    padding: 20px;
}

.table-events.p-datatable {
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);
    width: 97%;
    margin: 0 auto;
    border-top: 1px solid #353F4C;
}

.table-events.p-datatable .p-datatable-wrapper {
    max-height: 370px;
    overflow: auto;
    position: relative;
}

.table-events.p-datatable .p-datatable-wrapper {
    scrollbar-width: thin;
    scrollbar-color: #3f4b5b transparent;
}

.table-events.p-datatable .p-datatable-wrapper::-webkit-scrollbar {
    width: 9px;
}

.table-events.p-datatable .p-datatable-wrapper::-webkit-scrollbar-track {
    background: transparent;
}

.table-events.p-datatable .p-datatable-wrapper::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 3px;
}

.table-events.p-datatable .p-datatable-wrapper:hover::-webkit-scrollbar-thumb {
    background-color: #3f4b5b;
}

.table-events.p-datatable tr {
    height: 60px;
}

.table-events.p-datatable .p-datatable-thead>tr>th {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    color: var(--text-color);
    padding: 20px;
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: var(--surface-card);
    border-top: 1px solid #353F4C;
    border-bottom: 1px solid #353F4C;
}

.scroll-events {
    height: 370px;
    width: 97%;
    margin: 0 auto;
}


.table-events.p-datatable .p-datatable-tbody>tr>td {
    font-weight: 400;
    color: var(--text-color);
    padding: 20px;
    text-align: center;
}


.table-events.p-datatable .p-datatable-tbody>tr>td:first-child {
    text-align: start;
}

.table-events.p-datatable .p-datatable-tbody>tr>td:nth-child(2) {
    display: flex;
    justify-content: center;
}

.table-events.p-datatable .p-datatable-tbody>tr {
    border-top: 1px solid #353F4C;
    border-bottom: 1px solid #353F4C;
}

.table-events.p-datatable .p-datatable-tbody>tr:last-child {
    border-bottom: none;
}

.table-events tr th .p-column-header-content {
    display: flex;
    justify-content: center;
}

.table-events tr th:first-child .p-column-header-content {
    justify-content: start;
}

.tag-event {
    width: 65px;
    height: 20px;
    border-radius: 20px;
    background-color: #545B67;
}