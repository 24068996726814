.tags-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: var(--text-color);
    margin-bottom: 0;
}

.tags-container {
    background-color: transparent;
    display: flex;
    gap: 5px;
    border: 1px solid #78909C;
    margin-left: 0;
    padding-left: 0;
    flex-wrap: wrap;
    border-radius: 6px;
}

.tags-container li {
    list-style-type: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 39px;
    background-color: #FFFFFF1A;
    color: var(--text-color);
    border-radius: 4px;
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
}

.tags-container li.input_tags_container {
    background-color: transparent;
    padding: 0.25rem 0;

}

.tags-container li.input_tags_container .input-add_tags {
    border: 0 none;
    outline: 0 none;
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    padding: 0;
    font-size: 1rem;
    text-indent: 10px;
}