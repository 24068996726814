.limit-panel {
    // background: #2D3645;
    background-color: var(--surface-card);
    border: 1px solid #007AFF;
    border-radius: 20px;
    // position: absolute;
    // bottom: 50px;
    // left: 50%;
    // transform: translateX(-50%);
    height: 100%;
    width: 100%;
    padding: 30px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;

    .limit-panel-info {
        margin-right: 100px;
    }

    @media screen and (max-width: 990px) {
        flex-direction: column;
        .limit-panel-info {
            .limit-panel-title {
                text-align: center;
            }
            margin-bottom: 20px;
            margin-right: 0;
        }
    }

    &-warning {
        border: 1px solid #007AFF;
        .limit-panel-title {
            color: #007AFF;
        }
    }
    &-over {
        border: 1px solid #FF3B30;
        &-content {
            display: flex;
            align-items: center;
        }
        .limit-panel-pic {
            margin-right: 40px;
        }
        .limit-panel-title  {
            color: #FF3B30;
        }
    }

    &-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 22.5px;
        margin-bottom: 8px;
    }
    &-text {
        color: var(--text-color);
        font-size: 16px;
        line-height: 18px;
    }
    &-button {
        border: none;
        color: #fff;
        background: #007AFF;
        font-size: 16px;
        padding: 12px 20px; 
    }
}