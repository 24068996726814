.node-block {
    /* opacity: 0.2; */
    height: 100%;
    width: 100%;
    padding: 10px 20px 20px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: flex-start;
    text-align: left;
    border-radius: 6px;
    box-sizing: border-box;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #c3c3c3;
        font-size: 14px;
        margin-bottom: 20px;
        h3 {
            display: block;
            font-size: 14px;
            // letter-spacing: 2px;
            font-weight: 400;
            margin-bottom: 0;
            width: 90%;
        }
        .button-container {
            width: 10%;
            display: flex;
            justify-content: flex-end;
            button {
                cursor: pointer;
                font-weight: bold;
                height: 20px;
                display: flex;
                justify-content: center;
                border: none; 
                box-shadow: none !important;
                padding: 0;
            }
        }
    }
    h5 {
        font-size: 10px;
        font-weight: 700;
        margin-bottom: 10px;
        color: #ffffff1a;
    }
    // .transition .header h3 {
    //     text-align: left;
    //     color: #28cd41;
    // }
    .body {
        color: #fff;
        text-align: left;
        font-size: 12px;
        letter-spacing: 0.4px;
        line-height: 13.5px;
        /* border: 1px solid #000; */
        /* height: 42px; */
        /* overflow: hidden; */
        /* overflow-y: auto; */
        p {
            font-size: 12px;
        }
    }
    .footer {
        margin-top: 20px;
        &-with-files {
            border-radius: 4px;
            height: 32px;
            width: auto;
            background: rgba(255, 255, 255, 0.1);
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            img {
                cursor: pointer;
                border-radius: 6px;
                // width: 30px;
                // height: 24px;
                object-fit: cover;
                // margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                }
            }
          
        }
        
        &-separately-files {
            margin-top: 15px;
            h5 {
                color: #fff;
                font-weight: 700;
                font-size: 8px;
            }
            .separated-file {
                font-size: 12px;
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                line-height: 9px;
                svg, i {
                    margin-right: 10px;
                 }
            }
        }
    
    }
}


/* Стартовый Блок */
.StartBlock {
    width: 60px;
    height: 60px;
    color: #000;
    background: #28cd41;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 12px;
    box-sizing: border-box;
}

/* Блок комментария */
.CommentBlock {
    background: transparent;
    border: none;
    width: 200px;
    height: auto;
    text-align: left;
    border-left: 4px solid #9747ff;
    padding-left: 6px;
    p {
        font-size: 12px;
        line-height: 13.51px;
        color: var(--text-color);
    }
    &:hover {
        background: rgba(0, 0, 0, 0.2);
    }
}



/* Блок текст */
.NodeBlock {
    /* opacity: 0.5; */
    color: #fff;
    padding: 0;
    width: 260px;
    min-height: 160px;
    height: auto;
    border-radius: 6px;
    background: #545b67;

    border: 2px solid #FFFFFF1A;
    box-sizing: border-box;
    .body p {
        margin-bottom: 0;
    }
    .buttons-container {
        /* border: 1px solid #000; */
        width: 100%;
        min-height: 80px !important;
    }
}



/* Кнопки в Нодах */
.ButtonBlock {
    border-radius: 6px;
    border: none;
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    width: 242px;
    height: 30px;
    padding: 10px;
    color: #fff;
    background: #353f4c;
}

/* Блок формы */
.FormBlock {
    color: #fff;
    padding: 0;
    width: 260px;
    min-height: 110px;
    border: 1px solid #ffd60a;
    border-radius: 6px;
    background: #2d3645;
    .p-button-text {
        color: #007aff;
        font-size: 12px;
        padding: 5px 0;
    }
}

/* Блок Action */
.ActionBlock {
    color: #fff;
    padding: 0;
    width: 200px;
    min-height: 90px;
    border: 1px solid #ffd60a;
    border-radius: 6px;
    background: #2d3645;
    .body {
        margin-bottom: 0;
        .body-text-wrap {
            width: 160px;
            word-wrap: break-word;
        }
    }
}

/* Блок условия */
.ConditionBlock {
    position: relative;
    border: 1px solid #ffd60a;
    border-radius: 6px;
    padding: 0;
    color: #fff;
    width: 180px;
    min-height: 140px;
    background: #2d3645;
}

/* Блок паузы */
.PauseBlock {
    padding: 0;
    // min-width: 150px;
    width: 200px;
    min-height: 90px;
    border: 1px solid #007aff;
    border-radius: 6px;
    background: #007aff1a;
    // background: rgb(48 69 94);
    .node-block {
        .header {
            h3 {
                color: #fff;
            }
        }
        .body {
            // color: var(--text-color);
            margin-bottom: 0;
            .block-type {
                font-weight: 700;
                &.start {
                    color: #28cd41;
                }
                &.end {
                    font-weight: 700;
                    color: #ff3b30;
                }
            }
        }
    }
  
}

/* Блок перехода */
.TransitionBlock {
    padding: 0;
    width: 200px;
    height: 120px;
    border: 1px solid #28cd41;
    border-radius: 6px;
    background: #28cd411a;
    // background: rgb(52 78 75);
    .node-block {
        .header {
            color: #fff;
        }
        // .body {
        //     color: var(--text-color);
        // }
    }
    
}
/* Выбранный блок */
.selected > div:not(.CommentBlock) {
    box-shadow: 0 0 0 2px #9747ff;
            //   0 0 0 4px #66CCFF;
    // border: 2px solid #9747ff;
    border-radius: 6px;
}