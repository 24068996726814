.view-mailing {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.text-message_mailing {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.view-mailing.title-mailing {
    margin-bottom: 0;
    height: auto;
}

.p-button.p-button-icon-only.button-delete {
    background: transparent;
    border: none;
    box-shadow: none;
    color: #ff3b30;
    height: 20px;
    width: 20px;
}

.imgs-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: var(--surface-ground);
    height: 52px;
    border-radius: 6px;
    margin: 32px 0;
}

.img-item {
    display: flex;
    align-items: center;
}

.img-item img {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    margin-right: 16px;
}

.filter-item {
    background-color: var(--surface-ground);
    border-radius: 6px;
    margin-bottom: 8px;
    padding: 0 10px;
}

.filter-item_closed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
}

.filter-item h6 {
    margin-bottom: 0;
}

.footer-btns {
    display: flex;
    justify-content: space-between;

}

.mailing-btn_edit,
.p-button.mailing-btn_edit:hover {
    border: 1px solid #78909C;
    border-radius: 6px;
    background-color: var(--surface-ground);
    padding: 16px 18px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    min-width: 145px;
}

.mailing-btn_sent,
.p-button.mailing-btn_sent:hover {
    border: none;
    border-radius: 6px;
    background-color: var(--primary-color);
    padding: 16px 18px;
    min-width: 145px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.view-mailing_scroll {
    height: calc(100vh - 23.5rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.view-mailing_scroll .p-scrollpanel-content {
    flex-direction: column;
    width: calc(100% + 7px);
}