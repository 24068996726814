.sidemenu {
    height: calc(100vh - 9rem);
}

.p-overlaypanel.p-component:before,
.p-overlaypanel.p-component:after {
    display: none;
}

.overlay_panel-menu {
    border-radius: 20px;
    background-color: var(--surface-card);
    border: 1px solid var(--surface-border);
}

.overlay_panel-menu .p-overlaypanel-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.overlay_panel-menu .p-overlaypanel-content ul li a {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
}

.overlay_panel-menu .p-overlaypanel-content ul li a svg {
    fill: var(--text-color);
}

.overlay_panel-menu .p-overlaypanel-content ul li a:hover {
    background: #FFFFFF1A;
    border-radius: 24.5px;
}

.selected-company {
    border-radius: 6px;
    width: 100%;
    border: 1px solid #FFFFFF1A;
    background-color: #FFFFFF1A;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    color: var(--text-color);
}

.overlay_panel-menu .p-overlaypanel-content {
    /*     background-color: #2D3645;
    border: 1px solid #FFFFFF1A; */
    border-radius: 20px;
    padding: 30px 23px 20px;
}


.overlay_panel-menu .p-overlaypanel-content .sign_out {
    display: none;
    align-items: center;
    color: #FF3B30;
    cursor: pointer;
}

.overlay_panel-menu .p-overlaypanel-content .sign_out svg path {
    fill: #FF3B30;
}

.sidemenu li a span {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.sidemenu div li a.sign_out span {
    color: #FF3B30;
}


.sidemenu div li a.sign_out svg {
    fill: #FF3B30;
}

.card.setting {
    padding: 40px;
}

.sidemenu li a span {
    color: var(--text-color);
}

.sidemenu li a.active span {
    color: var(--primary-color)
}

a .topbar-user {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}


a .topbar-user {
    color: var(--text-color)
}

.avatar-user {
    width: 195px;
}

.avatar-user img {
    width: 100%;
}

.react-gravatar.CustomAvatar-image.topbar {
    border-radius: 15px;
    width: 40px;
    height: 40px;
}

.settings-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
}

.position {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-color);
}

.mail {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #566476
}

.subscription-card:hover {
    cursor: pointer;
}

.subscription-card {
    background-color: transparent;
    border-radius: 20px;
    padding: 25px;
}

.subscription-card.light.premium {
    border: 1px solid #FFE66D
}

.subscription-card.light.basic {
    border: 1px solid #4EC84B
}

.subscription-card.dark.premium {
    border: 1px solid #FFD60A1A
}

.subscription-card.dark.basic {
    border: 1px solid #FFFFFF1A
}

.settings-card {
    border: none;
    background-color: var(--surface-ground);
    border-radius: 20px;
    padding: 25px;
}

.settings-card p {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
}

.subscription-card.light .yellow,
.subscription-card.light .green {
    color: #293241;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}


.subscription-card.dark .green {
    color: #4EC84B;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.subscription-card.dark .yellow {
    color: #FFE66D;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.wrapper-green {
    width: 40px;
    height: 40px;
    background-color: #36EF541A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper-red {
    width: 40px;
    height: 40px;
    background-color: #EF3E361A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscription-card.dark.basic {
    background-image: url('../../../../public/assets/layout/images/Union.dark.svg');
    background-position: top 15% right 5%;
    background-size: 30%;
    background-repeat: no-repeat;
}

.subscription-card.light.basic {
    background-image: url('../../../../public/assets/layout/images/Union.light.svg');
    background-position: top 15% right 5%;
    background-size: 30%;
    background-repeat: no-repeat;
}

.basic.active {
    background-color: #36EF541A;
}

.subscription-card.dark.premium {
    background-image: url('../../../../public/assets/layout/images/premium.dark.svg');
    background-position: top right;
    background-size: 35%;
    background-repeat: no-repeat;
}

.subscription-card.light.premium {
    background-image: url('../../../../public/assets/layout/images/premium.light.svg');
    background-position: top right;
    background-size: 35%;
    background-repeat: no-repeat;
}

.premium.active {
    background-color: #FFE66D1A;
}

.p-inputswitch.settings-switch {
    width: 40px;
    height: 20px;
}

.settings-switch span.p-inputswitch-slider {
    border-radius: 36.5px;
    height: 20px;
    width: 40px;
    background: transparent;
    border: 0.5px solid #566476
}

.settings-switch span.p-inputswitch-slider::before {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    left: 2px;
}

.p-inputswitch.settings-switch .p-inputswitch-slider:before {
    background: var(--text-color);
}

.p-inputswitch.p-inputswitch-checked.settings-switch .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked.settings-switch .p-inputswitch-slider:hover {
    background: #28CD41;
}

.p-inputswitch.p-inputswitch-checked.settings-switch .p-inputswitch-slider:before {
    background: var(--text-color);
    width: 16px;
    height: 16px;
    margin-top: -8px;
    left: 0px;
}


.price span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.price p {
    font-size: 36px;
    font-weight: 400;
    line-height: 41px;
}

.p-button.btn-pay {
    background-color: var(--primary-color);
    color: var(--text-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    border: none;
    border-radius: 6px;
}

.img-block {
    max-width: 320px;
    height: 275px;
}

.p-button.btn-edit,
.p-button.btn-edit:hover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.p-button.btn-edit.dark,
.p-button.btn-edit.dark:hover {
    background-color: #FFFFFF1A;
}

.p-button.btn-edit.light,
.p-button.btn-edit.light:hover {
    background-color: #69B7FF1A;
}

.p-button.btn-trash,
.p-button.btn-trash:hover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #EF3E361A;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.employees_mail {
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.p-button.btn-main,
.p-button.btn-main:hover {
    height: 50px;
    background-color: var(--surface-ground);
    border: none;
    color: var(--text-color);
}

.personal-setting,
.attention {
    border-radius: 20px;
    border: none;
}

.personal-setting .p-dialog-header .p-dialog-header-icons,
.attention .p-dialog-header .p-dialog-header-icons {
    display: none;
}

.personal-setting .p-dialog-header,
.attention .p-dialog-header {
    border-bottom: none;
    border-radius: 20px 20px 0 0;
    padding: 40px 40px 0 40px
}

.personal-setting .p-dialog-header .p-dialog-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
}

.personal-setting .p-dialog-content,
.attention .p-dialog-content {
    padding: 40px
}

.personal-setting .p-dialog-footer,
.attention .p-dialog-footer {
    border-top: none;
    border-radius: 0 0 20px 20px;
    padding: 0 40px 30px 40px
}

.personal-setting .p-dialog-footer .btn-cancel_light,
.personal-setting .p-dialog-footer .btn-cancel_light:hover,
.attention .p-dialog-footer .btn-cancel_light,
.attention .p-dialog-footer .btn-cancel_light:hover {
    border: none;
    background-color: #FFFFFF;
    color: #293241;
}

.p-selectbutton.gender.dark .p-button.p-highlight {
    background-color: #FFFFFF1A;
    border: 1px solid #FFFFFF1A
}

.p-selectbutton.gender.light .p-button.p-highlight,
.p-selectbutton.gender.light .p-button:hover {
    background-color: #69B7FF1A;
    border: none;
    color: #293241;
}

.p-selectbutton.gender.dark .p-button {
    background-color: transparent;
    border: 1px solid #FFFFFF1A;
}

.p-selectbutton.gender.light .p-button {
    background-color: #FFFFFF;
    border: none;
    color: #293241;
}

.gender .p-button {
    border-radius: 6px;
    height: 50px;
    width: 50%;
    margin-top: 4px;
}

.personal-setting.dark input,
.personal-setting.dark .select-rights {
    border-radius: 6px;
    height: 50px;
    background-color: transparent;
}

.personal-setting.light input.p-inputtext,
.personal-setting.light .select-rights {
    border-radius: 6px;
    height: 50px;
    background-color: #FFFFFF;
    border: none;
}

.personal-setting.dark textarea {
    border-radius: 6px;
    background-color: transparent;
}

.personal-setting.light textarea {
    border-radius: 6px;
    background-color: #FFFFFF;
    border: none;
}

.label {
    font-size: 12px;
    margin-left: 13px;
}

.input-mail,
.input-position {
    color: #69B7FF
}

.select-rights {
    margin-top: 4px;
}

.surcharge_message {
    background-color: #007AFF1A;
    border: 1px solid #FFFFFF1A;
    padding: 25px;
    border-radius: 20px;
}

.surcharge_message .blue {
    color: #007AFF;
}

.btn-side_menu {
    display: none;
}

.settings-content {
    padding-left: 0;
}

.post {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: var(--primary-color);
}

@media screen and (max-width: 1200px) {
    .btn-side_menu {
        display: block;
    }

    .overlay_panel-menu .p-overlaypanel-content .sign_out {
        display: flex;
    }
}

@media screen and (min-width: 1200px) {
    .settings-content {
        flex: 0 0 auto;
        width: 80%;
        padding-left: 85px;
    }

}

@media screen and (min-width: 1550px) {
    .settings-content {
        flex: 0 0 auto;
        width: 58.3333%;
        min-width: 1110px;
        padding-left: 35px;
    }
}