.NotFound {
    background-image: url(../../../public/assets/layout/images/authorization.png);
    background-repeat: no-repeat;
    background-position: center;
    margin: -1rem -2rem -2rem -2rem;
    height: calc(100vh - 5rem);
}


@media screen and (max-width: 576px) {

    .NotFound {
        background-size: cover;
    }

    .not-found_numbers {
        max-width: 100%;
    }

    .not-found_numbers img {
        max-width: 100%;
    }

}