.description-company {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: var(--primary-color);
}

.settings-subtitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
}

.basic-information_label {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #566476;
}

.switch-label {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
}

.setting-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #566476;
}

.p-button-outlined.theme {
    width: 103px;
    height: 103px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent
}

.p-button-outlined.theme.active {
    border: 1px solid #69B7FF;
}

.theme:hover {
    cursor: pointer;
}

.personal-setting h6 {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: var(--primary-color);
}

.p-button.btn-add_phone.dark,
.p-button.btn-add_phone.dark:hover {
    background-color: #FFFFFF1A;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #FFFFFF;
}

.p-button.btn-add_phone.light,
.p-button.btn-add_phone.light:hover {
    background-color: #FFFFFF;
    border-radius: 6px;
    border: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #293241;
}

.add_phone select.PhoneInputCountrySelect,
.phone select.PhoneInputCountrySelect {
    display: none;
}

.add_phone .PhoneInputCountryIcon {
    width: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.phone.PhoneInput {
    display: flex;
    align-items: center;
}

.phone .PhoneInputCountryIcon {
    width: 20px;
}

.add_phone input.PhoneInputInput {
    border: 1px solid #FFFFFF1A;
    color: var(--text-color);
    padding-left: 14px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
}

.phone input.PhoneInputInput {
    border: none;
    color: var(--text-color);
    background-color: transparent;
    padding-left: 14px;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;

}

.add_phone input.PhoneInputInput:focus {
    outline: none;
}

img.react-gravatar {
    border-radius: 50%;
}

.p-datatable .p-datatable-tbody>tr.p-datatable-emptymessage>td {
    padding: 0;
}

.editData {
    min-width: 600px
}

@media screen and (max-width: 440px) {
    .p-button-outlined.theme {
        width: 85px !important;
        height: 85px !important;
    }
}

@media screen and (min-width: 576px) {
    .personal-setting {
        min-width: 600px
    }
}