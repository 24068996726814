.p-paginator.p-component {
    background-color: transparent;
    padding: 0;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    border: none;
}