.card-new_users {
    background-color: var(--primary-color);
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-new_users.audience {
    background-color: var(--surface-card);
    color: var(--text-color);
}

.card-new_users.audience .wrapper-text {
    height: 100%;
}

.card-new_users.dark {
    background-color: #36EF541A;
}

.card-new_users.light {
    background-color: #28CD41;
}