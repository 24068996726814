.chats svg,
.preview svg {
    fill: var(--surface-ground);
}

.header-tabel {
    font-size: 12px;
}

.funnels-subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    color: var(--text-color);
}

.messanger_card {
    border-radius: 20px;
    border: 1px solid var(--surface-border);
    background-color: transparent;
    padding: 0 15px;
    display: flex;
    gap: 20px;
    align-items: center;
    /*  width: 165px; */
    height: 40px;
}

div.no_active .messanger_card svg {
    fill-opacity: 0.1;
}

div.no_active .messanger_card span {
    color: var(--surface-border);
    opacity: 0.4;
}

div .messanger_card span.text {
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
}

div.messanger_card svg {
    fill: var(--text-color);
}



.messanger_card span {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
}

.tunnel-card {
    border-radius: 6px;
    background-color: var(--surface-ground);
    border: none;
}

.card_warning {
    background-color: #FF3B301A;
    border-radius: 6px;
}

.card_warning h6 {
    color: #FF3B30;
}

.card_warning p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.payment_data-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #545B67
}

.warning {
    width: 20px;
    height: 20px;
    background-color: #FF3B30;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.last_changes h6 {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #545B67;
}

.last_changes .name {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
}

.last_changes .email,
.last_changes .changes-date {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    color: var(--primary-color)
}

.last_changes {
    background-color: var(--surface-ground);
    border-radius: 6px;
    padding: 10px;
}

.additional_information button,
.additional_information .p-button:hover {
    border-radius: 6px;
    background-color: var(--surface-ground);
    border: 1px solid #FFFFFF1A;
    color: var(--text-color);
}
.attention-popup {
    width: 25vw;
}

.attention-symbol {
    width: 40px;
    height: 40px;
    background-color: #FF3B30;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attention-header {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #FF3B30;
}

.btn-disable,
.p-button.btn-disable:hover {
    background-color: #FF3B30;
    color: var(--text-color);
    border: none;
}

.attention p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.attention p span {
    color: #FF3B30;
}

.text-btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.view_funnel-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
}

.btn-edit_funnel,
.p-button.btn-edit_funnel:hover {
    border-radius: 12px;
    background-color: var(--primary-color);
    border: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--text-color);
    height: 65px;
    padding: 10px;
}

.btn_funnel,
.p-button.btn_funnel:hover {
    border-radius: 12px;
    background-color: var(--surface-ground);
    border: 1px solid var(--surface-border);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: var(--text-color);
    height: 65px;
    padding: 10px 0 !important;
}

@media screen and (max-width: 1740px) {
    .filters-block {
        display: none !important;
    }

    .btn-tag.filters {
        display: block !important;
    }

}



@media screen and (max-width: 1550px) {
    .additional_information .grid .col-12.sm\:col-6 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 100%;
    }
}

@media screen and (max-width: 1250px) {
    .btn-tag.filters {
        order: 1 !important;
        margin-top: 10px !important;
    }

    .create-funnel {
        order: 0 !important;
    }
}

@media screen and (max-width: 991px) {
    .additional_information .grid .col-12.sm\:col-6 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 50%;
    }

    .btn-tag.filters {
        order: 0 !important;
    }

    .create-funnel {
        order: 1 !important;
    }
}

@media screen and (max-width: 809px) {
    .btn-tag.filters {
        order: 1 !important;
    }

    .create-funnel {
        order: 0 !important;
    }
}

@media screen and (max-width: 482px) {
    .additional_information .grid .col-12.sm\:col-6 {
        flex: 0 0 auto;
        padding: 0.5rem;
        width: 100%;
    }
}

.messanger-item {
    display: block !important;
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%
}

.messanger-item.text {
    order: 1;
}

.messanger-item.whatsapp {
    order: 1;
}


.funnels-table tbody tr {
    border-bottom: none
}

.funnels-table tbody tr td:last-child {
    margin-bottom: 10px;
}

.funnels-table tbody tr td:first-child {
    margin-top: 10px;
}

@media screen and (max-width: 750px) {
    .attention-popup {
        width: 75vw;
    }
    .funnels-table tbody tr {
        border-bottom: 0.5px solid var(--surface-border);
    }
}
@media screen and (min-width: 750px) {
    .attention-popup {
        width: 50vw;
    }
}

@media screen and (min-width: 1200px) {
    .attention-popup {
        width: 35vw;
    }


    .messanger-item {
        width: 50%
    }

    .messanger-item.text {
        order: 1;
    }

    .messanger-item.whatsapp {
        order: 1;
    }
}

@media screen and (min-width: 1370px) {

    .attention-popup {
        width: 25vw;
    }

    .messanger-item {
        width: 33.3333%
    }

    .messanger-item.text {
        order: 0;
    }

    .messanger-item.whatsapp {
        order: 0;
    }
}

@media screen and (min-width: 1600px) {

    .messanger-item {
        width: 50%
    }

    .messanger-item.text {
        order: 1;
    }

    .messanger-item.whatsapp {
        order: 1;
    }
}

@media screen and (min-width: 1700px) {

    .messanger-item {
        width: 33.3333%
    }

    .messanger-item.text {
        order: 0;
    }

    .messanger-item.whatsapp {
        order: 0;
    }
}