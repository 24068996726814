.choice-bot {
    margin-bottom: 32px;
}

.droppdown-mailings {
    height: 45px;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid #78909C;
    width: 100%;
}

.input-mailings {
    height: 40px;
    border-radius: 6px;
    background-color: transparent;
    border: 1px solid #78909C;
    width: 100%;
}

.ql-snow.ql-toolbar button.btn-editor {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    padding: 0;
}

.btn-editor img {
    width: 100%;
}

.text-mailing .p-editor-toolbar.ql-toolbar {
    background-color: var(--surface-ground);
    border-radius: 6px 6px 0px 0px;
    border-width: 1px, 1px, 0px, 1px;
    border: 1px solid #78909C;
}

.text-mailing .p-editor-content.ql-container {
    min-height: 72px;
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #78909C;
}

.text-mailing .p-editor-content.ql-container .ql-editor {
    background-color: transparent;
}

.choice-date {
    display: flex;
    gap: 16px;
    margin: 24px 0;
}

.choice-date_calendar {
    border: 1px solid #78909C;
    border-radius: 6px;
    height: 40px;
}

.choice-date_calendar button.p-button.p-component.p-datepicker-trigger.p-button-icon-only,
.until-date_calendar button.p-button.p-component.p-datepicker-trigger.p-button-icon-only {
    background-color: transparent;
    border: none;
    color: var(--primary-color);
    border-left: none;
}

.choice-date_calendar .p-inputtext.p-component,
.until-date_calendar .p-inputtext.p-component {
    border-right: none;
}

.mailing-warning_text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #78909C;
    padding-bottom: 10px;
}

.choice-funnel {
    margin-top: 24px;
    padding-bottom: 10px;
}

.choice-tags {
    padding-bottom: 10px;
}

.choice-tags .tags-container {
    margin-bottom: 0
}

.mailing-btn_cancel,
.p-button.mailing-btn_cancel:hover {
    border: none;
    border-radius: 6px;
    background-color: var(--surface-ground);
    padding: 16px 18px;
    color: var(--text-color);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    min-width: 145px;
}

.add-mailing_scroll {
    height: calc(100vh - 18.5rem);
    overflow-x: hidden;
    overflow-y: auto;
}

.add-mailing_scroll .p-scrollpanel-content {
    flex-direction: column;
    width: calc(100% + 7px);
}

.button-mailing {
    margin-bottom: 32px;
}

.text-mailing .p-editor-container .p-editor-content .ql-editor:before {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;

}

.sending {
    margin-top: 32px;
}

.until-date {
    padding: 24px 0;
}

.until-date_calendar {
    border: 1px solid #78909C;
    border-radius: 6px;
    height: 40px;
    width: 100%;
}

.p-invalid.droppdown-mailings {
    margin-bottom: 5px;
}