.container-chart {
    width: 150px;
}

.chart-doughnut {
    position: relative;
    width: 150px
}


@media screen and (max-width: 1700px) {

    .block-diagram .container-chart {
        width: 100px;
    }

    .block-diagram .chart-doughnut {
        width: 100px
    }
}

@media screen and (max-width: 1600px) {

    .block-diagram .container-chart {
        width: 75px;
    }

    .block-diagram .chart-doughnut {
        width: 75px
    }

    .block-diagram .messangers-block {
        margin-left: 8px;
    }

    .block-diagram .diagram {
        align-items: flex-start;
        margin-top: 30px;
    }
}


@media screen and (max-width: 1490px) {
    .block-diagram .container-chart {
        margin: 0 auto;
    }

    .block-diagram .diagram {
        flex-direction: column;
    }

    .block-diagram .messangers-block {
        margin-top: 20px;
    }

}

@media screen and (max-width: 1199px) {
    .block-diagram .container-chart {
        margin: 0;
        width: 135px;
    }

    .block-diagram .chart-doughnut {
        width: 135px
    }

    .block-diagram .messangers-block {
        margin-left: 20px;
        margin-top: 0;
    }

    .block-diagram .diagram {
        flex-direction: row;
    }

}

@media screen and (max-width: 1100px) {
    .block-diagram .container-chart {
        width: 75px;
    }

    .block-diagram .chart-doughnut {
        width: 75px
    }

    .block-diagram .messangers-block {
        margin-left: 8px;
    }

    .block-diagram .diagram {
        align-items: flex-start;
        margin-top: 30px;
    }

}

@media screen and (max-width: 940px) {
    .block-diagram .container-chart {
        margin: 0 auto;
    }

    .block-diagram .diagram {
        flex-direction: column;
    }

    .block-diagram .messangers-block {
        margin-top: 20px;
    }

}

@media screen and (max-width: 750px) {
    .block-diagram .container-chart {
        margin: 0;
        width: 150px;
    }

    .block-diagram .chart-doughnut {
        width: 150px
    }

    .block-diagram .messangers-block {
        margin-left: 20px;
        margin-top: 0;
    }

    .block-diagram .diagram {
        flex-direction: row;
    }

}

@media screen and (max-width: 460px) {
    .block-diagram .container-chart {
        margin: 0;
        width: 90px;
    }

    .block-diagram .chart-doughnut {
        width: 90px
    }

}

@media screen and (max-width: 430px) {
    .block-diagram .container-chart {
        margin: 0 auto;
    }

    .block-diagram .diagram {
        flex-direction: column;
    }

    .block-diagram .messangers-block {
        margin-top: 20px;
    }

}