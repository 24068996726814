.toolbar {
    /* border: 1px solid #000; */
    height: calc(100vh - 80px);
    position: absolute;
    right: 0;
    padding-right: 10px;
    bottom: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 999;
    backdrop-filter: blur(2px);
    /*  transition: all 0.2s linear; */
    transition: width 0.5s;
    width: 90px;
    &.open {
        width: 200px;
        backdrop-filter: blur(5px);
        background: rgb(45 54 69 / 70%);
        .toolbar-option .toolbar-btn-title {
            color: #fff;
        }
    }
    &.close:hover {
        // background: rgb(45 54 69 / 70%);
    }
    .p-button {  
        box-shadow: none !important;
    }
}




.toolbar-sidebar .p-sidebar-header {
    display: none;
}

.toolbar-sidebar .p-sidebar-content {
    padding: 0;
}



.pulse {
    position: relative;
    /*  padding: 15px; */
    transition: all 0.5s ease-in-out;
    z-index: 9999;
    .add-message {
        color: #28CD41;
        z-index: 9999;
        transition: transform 1s;
        border: 1px solid #FFFFFF1A;
        margin-bottom: 4px;
       
        &:hover {
            color: #28CD41;
        }
        &.open {
            transform: rotate(45deg);
            color: #FF3B30;
            background: rgba(255, 59, 48, 0.1);
            border: 1px solid rgba(255, 255, 255, 0.1);
        }
        &::after, &::before {
            content: '';
            position: absolute;
            border: 2px solid #28cd418a;
            left: -5px;
            opacity: 0;
            right: -5px;
            top: -5px;
            bottom: -5px;
            border-radius: 50%;
            //  animation: pulse 4.5s linear infinite; 
        }
        &::after {
            animation-delay: 1.25s;
        }
    }
}


/* Анимация пульсации кнопки тулбара */
@keyframes pulse {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2);
        opacity: 0;
    }
}

/* Анимация появления */
.toolbar-options {
    /* border: 1px solid red; */
    display: hidden;
    max-height: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-right: -100px;
    width: 110px;
    align-items: center;
    opacity: 0;
    /* transform: translateX(0); */
    transition: all 0.5s ease-in-out;
    /* margin-right: 25px; */
    &.opened {
        transform: translateX(-100px);
        opacity: 1;
    }
}


.toolbar-items {
    transition: all 0.5s ease-in-out;
    opacity: 1;
}

.closed-toolbar.close .toolbar-items {
    opacity: 0;
    margin-top: 100px;

}

.closed-toolbar.close .pulse {
    transform: translateY(140px);
}


.toolbar-option {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-faq {
        position: absolute;
        bottom: 0;
        width: 70px;
    }
    .toolbar-btn {
        color: var(--text-color);
        border: none;
        border-radius: 50%;
        background: #545b67;
        width: 40px;
        height: 40px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #FFFFFF1A;
        &-save {
            background: #28cd41;
        }
        &-faq {
            background: #007aff;
        }
        &-comment {
            background: #9747ff;
        }
        &-title {
            font-size: 10px;
            text-align: center;
            font-weight: 400;
            color: var(--text-color);
            line-height: 10px;
        }
    }
}


.closed-toolbar .toolbar-option {
    margin: 24px 0;
}

.toolbar-item-text,
.closed-toolbar.close .toolbar-items .toolbar-item-text {
    display: block;
    height: 100%;
    transform: rotateX(-70deg);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.toolbar.open .toolbar-options .toolbar-item-text,
.closed-toolbar.open .toolbar-items .toolbar-item-text {
    transform: rotateY(0);
    opacity: 1;
    transition: transform 0.5s, opacity 0.2s;
}