.percent {
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
}

.legend {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.select {
    background-color: var(--surface-ground);
    border-radius: 6px;
}

.select.light {
    border: 1px solid #566476
}

.select.dark {
    border: none;
}

.p-divider.p-divider-horizontal.dark:before {
    border-top: 0.5px solid #FFFFFF1A;
}

.p-divider.p-divider-horizontal.light:before {
    border-top: 0.5px solid #566476;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding-top: 7px;
    padding-bottom: 7px;
}



.user-name {
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
}

.funnel {
    color: #F7FFF7;
}

.payment {
    color: #36EF54;
}

.user_answer {
    color: #69B7FF;
}

.smart_answer {
    color: #566476
}

/* .block {
    color: #EF3E36;
} */

.p-datatable .p-datatable-tbody>tr>td a.email {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
}

.switch span.p-inputswitch-slider {
    border-radius: 36.5px;
}

.switch span.p-inputswitch-slider::before {
    border-radius: 50%;
}


.p-inputswitch.p-inputswitch-checked.switch .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked.switch .p-inputswitch-slider:hover {
    background: #4EC84B;
}

.p-inputswitch.p-inputswitch-checked.switch .p-inputswitch-slider:before {
    background: #F7FFF7;
}

.p-divider.p-divider-vertical:before {
    border-left: 0.5px solid #E5E5E5;
}

.p-button.p-button-outlined.btn-tag {
    background-color: var(--surface-ground);
    border: none;
}

.p-button.p-button-outlined.btn-tag span {
    color: var(--text-color)
}

.p-dialog.dialog-tag {
    border-radius: 20px;
    border: none;
}

.p-dialog.dialog-tag .p-dialog-header {
    border-radius: 20px 20px 0 0;
    border-bottom: none;
    padding: 20px;
    background-color: #353F4C;
}

.p-dialog.dialog-tag .p-dialog-footer {
    border-radius: 0 0 20px 20px;
    border-top: none;
    background-color: #353F4C;
    text-align: start;
}

.p-dialog.dialog-tag .p-dialog-content {
    background-color: #353F4C;
    padding: 20px;
}

.p-dialog.dialog-tag .p-dialog-content input {
    border: none;
    background-color: #2D3645;
    height: 48px;
}

.p-dialog.dialog-tag .p-dialog-content p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #566476
}

.p-button.btn-save,
.p-button.btn-save:hover {
    background-color: #566476;
    color: #FFFFFF;
    border: 1px solid #566476;
}

.p-button.btn-save.green,
.p-button.btn-save.green:hover {
    background-color: #28CD41;
    color: #FFFFFF;
    border: none
}

.p-button.p-button-text svg {
    fill: #FFFFFF;
}

.p-button.p-button-text.dark svg {
    fill: #FFFFFF;
    fill-opacity: 0.1;
}

.p-button.p-button-text.active svg {
    fill: var(--text-color);
    fill-opacity: 1;
}

@media screen and (max-width: 1600px) {
    .block-diagram .percent {
        font-size: 18px;
        line-height: 22px;
    }

}

@media screen and (max-width: 1200px) {
    .block-diagram .percent {
        font-size: 36px;
        line-height: 44px;
    }

}

@media screen and (max-width: 1100px) {
    .block-diagram .percent {
        font-size: 18px;
        line-height: 22px;
    }

}

@media screen and (max-width: 750px) {
    .block-diagram .percent {
        font-size: 36px;
        line-height: 44px;
    }

}