.sidemenu li a svg {
    fill: var(--text-color);
}

.sidemenu li a.active svg {
    fill: var(--primary-color);
}

.p-button.payments-card,
.p-button.payments-card:hover {
    min-width: 247px;
    height: 150px;
    border-radius: 20px;
    border: 1px solid #FFFFFF1A;
    background-color: var(--surface-ground);
    padding: 20px;
}

.payments-card {
    min-width: 247px;
    height: 150px;
    border-radius: 20px;
    border: 1px solid #FFFFFF1A;
    background-color: transparent;
    padding: 20px;
}

.p-button.payments-card,
.p-button.payments-card:hover {
    color: var(--text-color);
}

.p-button.payments-card.add,
.p-button.payments-card.add:hover {
    width: 247px;
    height: 150px;
    border-radius: 20px;
    padding: 20px;
    background-color: transparent;
}

.p-button.payments-card.add.light,
.p-button.payments-card.add.light:hover {
    border: 1px solid #566476;
}

.p-button.payments-btn,
.p-button.payments-btn:hover {
    width: 255px;
    height: 154px;
    border-radius: 20px;
    border: none;
    background-color: #FFFFFF1A;
    padding: 20px;
    color: var(--text-color);
}

.payments-btn_text {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
}


.wrapper-payment_sistem {
    width: 34px;
    height: 24px;
    border: 1px solid #D1D9E0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper-payment_sistem.dark {
    background-color: #FFFFFF;
}

.wrapper-payment_sistem.light {
    background-color: #EEF0F2;
}

.p-button.payments-card p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.bank-card {
    width: 375px;
    height: 193px;
    background-color: var(--surface-ground);
    border: 1px solid #FFFFFF1A;
    border-radius: 20px;
}

.bank-card.light {
    border: 1px solid #EEF0F2
}


.number-card {
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
}

.user-card {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #69B7FF;
}

.logo-card {
    width: 82px;
}

.bank-card .absolute {
    padding: 22px;
    height: 100%;
    top: 0;
    width: 100%;
}

.dark-band {
    height: 48px;
    margin-top: 35px;
}

.dark-band {
    background-color: var(--surface-card);
}

.cvc p {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.personal-setting .p-dialog-footer .btn-untie.dark,
.personal-setting .p-dialog-footer .btn-untie.dark:hover {
    background-color: #FFE66D1A;
    border: 1px solid #FFE66D;
    color: #FFFFFF;
}

.personal-setting .p-dialog-footer .btn-untie.light,
.personal-setting .p-dialog-footer .btn-untie.light:hover {
    background-color: #FFE66D;
    border: none;
    color: #293241;
}

.personal-setting input.p-inputtext.valid-month {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.personal-setting input.p-inputtext.valid-month.light {
    border-right: 2px solid #EEF0F2;
}

.personal-setting input.p-inputtext.valid-year {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.card-mini {
    width: 80px;
    height: 100px;
    border-radius: 6px;
    padding: 10px;
    border: 1px solid #FFFFFF1A;
}

.card-mini {
    background-color: var(--surface-ground);
    color: var(--text-color)
}


.p-datatable .p-datatable-tbody>tr>td p.sum {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
}


.p-datatable .p-datatable-tbody>tr>td p.sum.receiving {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
    color: #4EC84B;
}

.p-datatable .p-datatable-tbody>tr>td p.date-payment {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #566476;
}

.p-datatable .p-datatable-tbody>tr>td p.payment-action {
    font-size: 24px;
    font-weight: 400;
    line-height: 27px;
}

.success-content {
    margin-left: 150px;
}

h1.success-content_title {
    font-size: 42px;
    font-weight: 700;
    line-height: 47px;
    color: var(--primary-color);
    margin-top: 100px;
    margin-bottom: 27px;
}

.success-content_text {
    font-size: 32px;
    font-weight: 400;
    line-height: 36px;
    max-width: 700px;
}

.btn-back_main,
.p-button.btn-back_main:hover {
    background-color: #545B67;
    border: none;
    border-radius: 6px;
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    margin-top: 70px;
}